import useSWR from 'swr'
import Card from './Card'
import { SectionLoader } from '../Loaders'
import '../../styles/products.css'
import { fetcher } from '../../utils/functions/fetcher'
import CardFav from './CardFav'
import { useTranslation } from 'react-i18next'

const CardId = ({ id, fav = false }) => {
    const { t } = useTranslation()
    const adjustId = id.replace(' & ', '%20and%20')
    const url = `https://data.powershotz.com/php/searchForProduct.php?id=${adjustId}`

    const { data, error, isLoading } = useSWR(url, fetcher)

    if (error) return <div className='card'>{t('main.error')}</div>
    if (isLoading) return <div className='card'><SectionLoader /></div>
    if (!data) return null

    return fav
        ? <CardFav product={data} />
        : <Card product={data} />

}

export default CardId