import { useParallax } from 'react-scroll-parallax'
import '../../styles/headerImg.css'

const HeaderImg = (props) => {
    const { gif } = props

    const { ref } = useParallax({
        speed: -100,
    })

    return (
        <img
            src={gif}
            alt="powershotz"
            loading='lazy'
            className='headerImg'
            ref={ref}
            sizes="(max-width: 768px) 100vw,
        (max-width: 1200px) 100vw,
        100vw"
        />
    )
}

export default HeaderImg