import { useState, useEffect } from 'react'
import Header from '../comps/home/Header'
import Footer from '../comps/elements/Footer'
import OpenAStore from '../comps/home/OpenAStore'
import VideosPhotos from '../comps/home/VideosPhotos'
import CastingCalls from '../comps/home/CastingCalls'
import NewsAndUpdates from '../comps/home/NewsAndUpdates'
import ShippableShopz from '../comps/home/ShippableShopz'
import ScriptOrModelApp from '../comps/home/ScriptOrModelApp'
import DownloadableShopz from '../comps/home/DownloadableShopz'
import '../styles/home.css'
import { getPowershopzData } from '../utils/sql/getPowershopzData'

const Home = () => {
    window.scrollTo(0, 0)

    const [castingCalls, setCastingCalls] = useState()
    const [downloadable, setDownloadable] = useState()
    const [shippable, setShippable] = useState()
    const [gif, setGif] = useState()
    const [pzcode, setPzcode] = useState()
    const [gif2, setGif2] = useState()

    useEffect(() => {
        getPowershopzData(setCastingCalls, setDownloadable, setShippable, setGif, setPzcode, setGif2)
    }, [])

    return (
        <div>
            <Header gif={gif2} />
            <div className='mainCardContainer' style={styles.main}>
                <NewsAndUpdates gif={gif} pzcode={pzcode} />
                <VideosPhotos />
                <DownloadableShopz downloadable={downloadable} />
                <ShippableShopz shippable={shippable} />
                <CastingCalls castingCalls={castingCalls} />
                <ScriptOrModelApp />
                <OpenAStore />
            </div>
            <Footer />
        </div>
    )
}

const styles = {
    main: {
        marginBottom: 70,
    }
}

export default Home