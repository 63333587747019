export const getGifData = async (id) => {
    let lcId = id.toString().toLowerCase().replace(/ /g, '')

    if (lcId.includes('bianca')) lcId = 'bianca'

    const url = `https://data.powershotz.com/php/getGifData.php?id=${lcId}`

    try {
        const res = await fetch(url)
        const data = await res.json()
        const array = []
        data.map(i => {
            if (i.fileName !== '') {
                array.push(i.fileName)
            }
            return true
        })

        return array ? array : []
    } catch (e) {
        console.log(e)
        return false
    }
}