import CardId from '../cards/CardId'
import { sortByModelName } from '../../utils/functions/sortByModelName'

const StarsIn = (props) => {
    const { product, starsIn } = props

    if (!product || !starsIn) return null

    const starsInData = starsIn.filter(i => i.vid_id === product.id)
    const modelsStarIn = sortByModelName(starsInData)

    return (
        <>
            <h3 className={`prod_subheading`}>Stars:</h3>

            {modelsStarIn && modelsStarIn.map(i => {
                return <CardId key={i.model_name} id={i.model_name} />
            })}
        </>
    )
}

export default StarsIn