import { getImageLink } from './getImageLink'
import { getMedia } from './getMedia'
import { getGifData } from '../../../utils/sql/getGifData'
import { findProduct } from '../../../utils/sql/findProduct'
import { getModelData } from '../../../utils/sql/getModelData'
import { getStarsInData } from '../../../utils/sql/getStarsInData'

export const setProductData = async (pathname, setProduct, setMediaList, setImageLink, setStarsInData) => {
    if (!pathname) return

    let id = pathname
        .replace(' & ', '')
        .replace('/', '')
        .replace(' ', '')
        .replace(/%20/g, '')

    let product, mediaList, imageLink, starsInData = null

    product = await findProduct(id)
    const gifData = await getGifData(id)
    const modelData = await getModelData(id)
    const productId = product && product.id ? product.id : ''
    starsInData = await getStarsInData(id, productId)
    imageLink = getImageLink(product)
    mediaList = getMedia(product, imageLink, gifData, modelData)

    // console.log('SET PRODUCT DATA')
    // console.log("ID: ", id)
    // console.log('PRODUCT: ', product)
    // console.log("GIF DATA", gifData)
    // console.log("MODEL DATA", modelData)
    // console.log("STARS IN DATA", starsInData)
    // console.log("MEDIA LIST", mediaList)

    setProduct(product)
    setMediaList(mediaList)
    setImageLink(imageLink)
    setStarsInData(starsInData)
}