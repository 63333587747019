import PaymentPicker from '../payments/PaymentPicker'
import EmailComment from './EmailComment'

const CartForm = (props) => {
    const {
        email,
        setEmail,
        comment,
        setComment,
        setError,
        error,
        coin,
        setCoin,
        coins,
        txId,
        setTxId,
        coinPrice,
        setCoinPrice,
        total,
        fixedOrderNum,
        saveOrder,
        emailRef,
        paymentRef,
    } = props

    return (
        <div>
            <div ref={emailRef}></div>
            <EmailComment
                email={email}
                setEmail={setEmail}
                comment={comment}
                setComment={setComment}
                setError={setError}
                error={error}
            />

            <div ref={paymentRef}></div>
            <PaymentPicker
                coins={coins}
                coin={coin}
                setCoin={setCoin}
                coinPrice={coinPrice}
                total={total}
                txId={txId}
                setTxId={setTxId}
                setCoinPrice={setCoinPrice}
                orderId={fixedOrderNum}
                email={email}
                saveOrder={saveOrder}
                setError={setError}
                error={error}
            />
        </div>
    )
}

export default CartForm