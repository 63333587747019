import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import SmAddDvdBtn from '../buttons/SmAddDvdBtn'
import SmAddToCartBtn from '../buttons/SmAddToCartBtn'
import SmallAddToListBtn from '../buttons/SmAddToListBtn'
import { highlight } from '../highlight'
import '../../styles/card.css'
import { isInCart } from '../../utils/localStorage/functions'
import { formatPrice } from '../../utils/functions/formatPrice'
import { formatLength } from '../../utils/functions/formatLength'

const num = Math.floor(Math.random() * 4) + 1

const Card = ({ product, searchPhrase = '' }) => {
    const { t } = useTranslation()

    const modSearchPhrase = searchPhrase
        .replace(/\+/g, ' ')
        .replace(/-/g, ' ')

    const [isItInCart, setIsItInCart] = useState(false)

    const price = formatPrice(parseFloat(product.price))
    const id = product.pz_code ? product.pz_code : product.id

    const title = highlight(modSearchPhrase, product.title)

    const description = product.description
    const isDvd = product.pz_code ? true : false

    let length, src, keyword, link, type

    useEffect(() => {
        setIsItInCart(isInCart(product.id))
    }, [product.id])

    if (!product) return null

    if (modSearchPhrase !== '') {
        keyword = modSearchPhrase && product.keywords ? product.keywords.split(' ').find(i => i.toLowerCase().includes(modSearchPhrase)) : null
    }

    if (product.type === 'clip') {
        length = `${formatLength(product.length)} ${t('main.minutes')}`
        src = `https://data.powershotz.com/c4sImages/${product.image}`
        link = `/${product.pz_code ? product.pz_code : product.id}`
        type = `(${t('main.video')})`
    } else {
        length = `${product.length} ${t('main.photos')}`
        src = `https://data.powershotz.com/models2/${product.title.replace(/ /g, '').toLowerCase()}-${num}.jpg`
        link = `/${product.title}`
        type = `(${t('main.photos')})`
    }

    return (
        <div id='card'>
            <div className='card'>
                <Link to={link} className='imgContainer'>
                    <img
                        className='cardImage'
                        src={src}
                        alt={title}
                        width={300}
                        height={300}
                    />
                </Link>

                <div className='productInfo'>
                    <Link
                        to={link}
                        className='card_link'
                    >
                        <span className='card_title'>{title}</span>

                        <span className='type'>{type}</span>

                        <button className='button'>{t('main.more')}</button>
                    </Link>

                    <p className='card_paragraph'>{highlight(modSearchPhrase, description)}</p>

                    {keyword &&
                        <small style={styles.keyword}>{t('main.keyword')}: <span style={{ textTransform: 'uppercase' }}>{highlight(modSearchPhrase, keyword.replace(',', ''))}</span></small>
                    }

                    <div className='cardInfo'>
                        <span>{highlight(modSearchPhrase, id)}</span>
                        <span style={{ paddingLeft: 30 }}>${price}</span>
                        <span>{length}</span>
                    </div>

                    <div className='cardActions'>
                        <SmAddToCartBtn
                            isItInCart={isItInCart}
                            setIsItInCart={setIsItInCart}
                            id={product.id}
                            title={product.title}
                        />

                        {isDvd &&
                            <SmAddDvdBtn id={product.pz_code} />
                        }

                        <SmallAddToListBtn
                            id={product.id}
                            title={product.title}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

const styles = {
    keyword: {
        fontSize: 11,
        transform: 'translateY(-5px)'
    }
}

export default Card