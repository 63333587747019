import Select from 'react-select'
import { useTranslation } from 'react-i18next'
import { generateArray } from './generateArray'
import '../../styles/cart.css'

const customStyle = {
    option: (provided, state) => ({
        ...provided,
        color: '#2d283e',
    })
}

const SelectVideos = (props) => {
    const { t } = useTranslation()
    const { dvdData, dvds, setDvds } = props

    const handleChange = (val) => {
        const items = []
        val.forEach(item => {
            items.push(item.value)
        })

        const newSet = [...new Set(items)]
        localStorage.setItem('dvds', JSON.stringify(newSet))
        setDvds(newSet)
    }

    return (
        <div className={`cartItemsContainer`} style={styles.main}>
            <h2 className={`cartItemsContainerHeading`}>
                {t('dvd.add')}
            </h2>
            <p>{t('dvd.add_text')}</p>

            <Select
                isMulti
                name="order"
                options={dvdData}
                onChange={handleChange}
                styles={customStyle}
                value={generateArray(dvds, dvdData)}
            />
        </div>
    )
}

const styles = {
    main: {
        padding: '0 20px 20px 20px',
    }
}

export default SelectVideos
