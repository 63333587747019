import { useTranslation } from 'react-i18next'
import PowershopzHeader from './PowershopzHeader'

const OpenAStore = () => {
    const { t } = useTranslation()

    return (
        <div className='cardOpenStore'>
            <PowershopzHeader title={t('open_store.sell_stuff')} />

            <p>{t('open_store.place')}</p>

            <a
                href="https://powershopz.com/login"
                alt="Powershopz"
                target="_blank"
                rel="noreferrer"
            >
                <h2>{t('open_store.now')}</h2>
            </a>

            <ul className='listStyle'>
                <li>{t('open_store.li_1')}</li>
                <li>{t('open_store.li_2')}</li>
                <li>{t('open_store.li_3')}</li>
                <li>{t('open_store.li_4')}</li>
                <li>{t('open_store.li_5')}</li>
                <li>{t('open_store.li_6')}</li>
                <li>{t('open_store.li_7')}</li>
            </ul>
        </div>
    )
}

export default OpenAStore