import { useState } from 'react'
import { ImCheckboxChecked, ImCheckboxUnchecked } from 'react-icons/im'
import '../../styles/favorites.css'
import { updateItem } from '../../utils/localStorage/functions'
import { useTranslation } from 'react-i18next'

const PurchasedBtn = (props) => {
    const { t } = useTranslation()
    const { item } = props

    const [checked, setChecked] = useState(item.checked)

    const handleClick = () => {
        updateItem('list', item.id)
        setChecked(checked => !checked)
    }

    const icon = checked
        ? <ImCheckboxChecked
            size={20}
            title={t('favs.uncheck')}
        />
        : <ImCheckboxUnchecked
            size={20}
            title={t('favs.check')}
        />

    return (
        <div onClick={handleClick} className='listIcon'>
            <div>{icon}</div>
            <div className='listIconLabel'>{t('favs.checkbox')}</div>
        </div>
    )
}

export default PurchasedBtn