import PowershopzHeader from './PowershopzHeader'
import { formatPrice } from '../../utils/functions/formatPrice'
import { useTranslation } from 'react-i18next'

const DownloadableShopz = (props) => {
    const { t } = useTranslation()
    const { downloadable } = props

    return (
        <div className='cardGridDownloadable'>

            <PowershopzHeader
                title={t('home.newest_downloadable')}
            />

            <div className='powershopzSection'>
                {downloadable &&
                    downloadable.map((product) => {
                        const sale = parseInt(product.sale)
                        const price = product.price
                            ? formatPrice(product.price)
                            : 0
                        const salePrice = product.salePrice
                            ? formatPrice(product.salePrice)
                            : 0

                        return (product.category === 'videos' || product.category === 'photos') && (
                            <div className='latestProductsMap' key={product.id}>
                                <img
                                    src={product.image}
                                    width={100}
                                    alt={product.title}
                                    className='latestImg'
                                />
                                <p>
                                    <span className='productTitle'>{product.title}</span><br />

                                    <span>{product.description.slice(0, 325)}{product.description.length > 325 ? ' ...' : ''}{' '}</span>

                                    <br />

                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={`https://powershopz.com/detail/${product.storeName}/${product.title}`}
                                    >
                                        [{t('main.see_more')}]
                                    </a>
                                </p>

                                <p className='priceComponent'>{t('main.only')}{' '}
                                    <span className='price'>
                                        ${sale
                                            ? salePrice
                                            : price
                                        }
                                    </span>
                                    <span className='sale'> {sale
                                        ? ` ${t('main.on_sale')}! `
                                        : ' '}</span>
                                    <br />
                                    <a target="_blank"
                                        rel="noopener noreferrer"
                                        className='priceComponent'
                                        href={`https://powershopz.com/${product.storeName}`}>{product.storeName}</a>
                                    <img
                                        src={"/images/newTabBlue.png"}
                                        className='copyImg'
                                        width={10}
                                        height={10}
                                        alt=""
                                    />
                                </p>
                                <hr />
                            </div>
                        )
                    })}
            </div>
        </div>
    )
}

export default DownloadableShopz