import SearchComp from '../comps/search/SearchComp'
import MetaDecorator from '../helmet/MetaDecorator'
import '../styles/forms.css'

const Search = () => {
    return (
        <div className='containerSearch'>

            <MetaDecorator title="Search" />

            <SearchComp />

        </div>
    )
}

export default Search