export const isVideo = (videoUri) => {
  const type = videoUri.split('.').pop().toLowerCase()
  switch (type) {
    case 'webm':
      return true
    case 'mpg':
      return true
    case 'mp2':
      return true
    case 'mpeg':
      return true
    case 'mpe':
      return true
    case 'mpv':
      return true
    case 'ogg':
      return true
    case 'mp4':
      return true
    case 'm4p':
      return true
    case 'm4v':
      return true
    case 'avi':
      return true
    case 'wmv':
      return true
    case 'mov':
      return true
    case 'qt':
      return true
    case 'flv':
      return true
    case 'swf':
      return true
    case 'avchd':
      return true
    default:
      return false
  }
}
