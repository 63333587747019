import { useTranslation } from 'react-i18next'
import { shippingAmount } from './shippingAmount'
import '../../styles/cart.css'
import { formatPrice } from '../../utils/functions/formatPrice'

const DvdTotalAndOrderNum = (props) => {
    const { t } = useTranslation()
    const { total, orderNumber, numberOfDvds } = props
    const shipping = numberOfDvds > shippingAmount
        ? numberOfDvds
        : shippingAmount

    return (
        <div className='totalOrderNumber'>
            <h2>
                <span className='fade'>{t('cart.order')}&nbsp;&nbsp;</span>
                #{orderNumber}
            </h2>

            <div>
                <h3 className='subtotal'>
                    <span className='fade'>{t('cart.subtotal')}</span>
                    ${formatPrice(total)}
                </h3>

                <h3 className='shipping'><span className='fade'>{t('dvd.shipping')}</span>
                    ${formatPrice(shipping)}
                </h3>

                <h2 className='total'>
                    <span className='fade'>{t('cart.total')}</span>
                    ${formatPrice(total + shipping)}
                </h2>
            </div>
        </div>
    )
}

export default DvdTotalAndOrderNum