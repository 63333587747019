import { GiClick } from "react-icons/gi"
import { useTranslation } from 'react-i18next'
import { isMobile } from 'react-device-detect'
import CartItemsList from './CartItemsList'
import '../../styles/paymentPicker.css'

const ReviewAndPlaceOrder = (props) => {
    const { t } = useTranslation()
    const {
        handleClick,
        // orderNumber,
        small
    } = props

    return (
        <div className={'pp_main'}>
            <h2 style={styles.heading} className={`altcoinText`}>
                <span style={styles.number}>3</span>
                {t('cart.review')}
            </h2>
            {/*
            <p className='orderNumberBorder'>{t('cart.order')} #{orderNumber}</p> */}

            <CartItemsList modal={true} />

            <div style={small
                ? styles.buttonAndTermSmall
                : styles.buttonAndTerm}>
                <button
                    title={`${t('payment.click').toUpperCase()}`} onClick={handleClick}
                    style={small
                        ? styles.smallButton
                        : styles.bigButton}
                >
                    {t('cart.place_order')}
                    <GiClick style={styles.clickIcon} />
                </button>

                {!small &&
                    <p style={styles.bigText}
                    >{t('payment.click')}</p>
                }
            </div>
        </div>
    )
}

const styles = {
    main: {
        alignItems: 'center',
        marginTop: 15,
        border: '1px solid  #2d283e',
        borderRadius: 5,
        padding: '20px 30px 30px 30px',
        background: 'rgba(45, 40, 62, 0.05)',
        margin: '0 auto 10px auto',
    },
    heading: {
        fontSize: '1.8rem',
        marginTop: 0,
    },
    buttonAndTerm: {
        textAlign: 'center'
    },
    buttonAndTermSmall: {
        textAlign: 'left',
    },
    bigButton: {
        width: '75%',
        height: 55,
        fontSize: isMobile ? '1.1rem' : '1.5rem',
    },
    smallButton: {
        width: '75%',
        fontSize: '1.1rem',
        marginLeft: 0,
    },
    clickIcon: {
        marginLeft: 10,
    },
    number: {
        marginRight: 15,
    },
    smallText: {
        fontSize: '0.8rem',
        textAlign: 'left',
        width: '80%',
    },
    bigText: {},
}

export default ReviewAndPlaceOrder