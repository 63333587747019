import { sort } from 'fast-sort'

export const sortByVideoId = (list) => {
    return sort(list).desc(u => {
        const id = u.vid_id.toString().toLowerCase()

        if (id.includes('vl')) {
            const split = id.split('vl')
            const num = parseInt(split[1])
            return 50000000 + num
        } else if (id.includes('pzv')) {
            const split = id.split('pzv')
            const num = parseInt(split[1])
            return 40000000 + num
        } else {
            return parseInt(id)
        }
    })
}
