import dayjs from 'dayjs'

const createArray = (items) => {
    const arr = []
    const split = items.split(',')
    split.map(i => arr.push(i))
    return arr
}

export const formatOrder = (order) => {
    if (!order) return null
    const data = {
        ...order,
        created_at: dayjs(order.created_at).format('MMMM DD, YYYY'),
        unformattedDate: order.created_at,
        total: parseFloat(order.total),
        items: createArray(order.items),
        paid: parseInt(order.paid),
        sent: parseInt(order.sent),
    }
    return data
}