import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import '../../styles/forms.css'

const Application = () => {
    const { t } = useTranslation()
    const [type, setType] = useState('')
    const [description, setDescription] = useState('')
    const [offer, setOffer] = useState('')
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')
    const [show, setShow] = useState(false)

    const clearForm = () => {
        setType('')
        setDescription('')
        setOffer('')
        setEmail('')
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setMessage(t('main.sending'))
        const data = { type, description, offer, email }

        const url = 'https://data.powershotz.com/php/requests.php'

        fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
        })
            .then(() => setMessage(t('main.success')))
            .then(() => clearForm())
            .catch(e => setMessage(e.message))
    }

    return <div className='form_main'>
        <ul className='form_ul'>
            <li>{t('home.application_1')}</li>
            <li>{t('home.application_2')}</li>
            <li>{t('home.application_3')}</li>
            <li>{t('home.application_4')}</li>
        </ul>

        <button onClick={() => setShow(!show)}>
            {show
                ? t('main.hide')
                : t('main.show')}
        </button>

        {show &&
            <form onSubmit={handleSubmit} className='form'>
                <input
                    onChange={(e) => {
                        setType(e.target.value)
                        setMessage('')
                    }}
                    name='type'
                    className='input'
                    value={type}
                    required
                />
                <label htmlFor='type'>
                    <p className='form_label'>{t('application.request_type')}</p>
                </label>
                <p className='labelDescription'>{t('application.types')}</p>

                <textarea
                    onChange={(e) => {
                        setDescription(e.target.value)
                        setMessage('')
                    }}
                    name='description'
                    className='input'
                    value={description}
                    required
                />

                <label htmlFor='description'>
                    <p className='form_label'>{t('application.description')}</p>
                </label>
                <p className='labelDescription'>{t('application.describe')}</p>

                <textarea
                    onChange={(e) => {
                        setOffer(e.target.value)
                        setMessage('')
                    }}
                    name="offer"
                    className='input'
                    value={offer}
                    required
                />

                <label htmlFor='offer'>
                    <p className='form_label'>{t('application.offer')}</p>
                </label>
                <p className='labelDescription'>{t('application.your_offer')}</p>

                <input
                    onChange={(e) => {
                        setEmail(e.target.value)
                        setMessage('')
                    }}
                    name="email"
                    className='input'
                    value={email}
                    required
                />

                <label htmlFor='email'>
                    <p className='form_label'>{t('application.email_phone')}</p>
                </label>
                <p className='labelDescription'>{t('application.how_contact')}</p>

                <div className='form_container'>
                    <button className='form_button'>{t('main.submit')}</button>
                </div>

                <p className='message'>
                    {message}
                </p>
            </form>}
    </div>
}

export default Application