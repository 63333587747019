import CardId from '../cards/CardId'
import { sortByVideoId } from '../../utils/functions/sortByVideoId'

const ModelVideos = (props) => {
    const { product, starsIn } = props

    const videoData = starsIn.filter(i => i.model_name === product.title)

    const videos = sortByVideoId(videoData)
    const s = videos.length === 1 ? '' : 's'
    const stars = product.title.includes('&') ? 'star' : 'stars'

    return <>
        <h3 className={`prod_subheading`}>{product.title} {stars} in {videos && videos.length} video{s}:</h3>

        {videos && videos.map(i => {
            return <CardId key={i.vid_id} id={i.vid_id} />
        })}

    </>
}

export default ModelVideos