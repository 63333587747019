export const structuredProduct = (
    title,
    image,
    description,
    url,
    price
) => {

    const date = new Date(new Date()
        .setFullYear(new Date()
            .getFullYear() + 1))

    return `[{
        "@context": "http://schema.org",
        "@type": "Product",
        "name": "${title}",
        "image": "${image}",
        "description": "${description.replace(/"/g, '')}",
        "url": "${url}",
        "brand": {
            "@type": "Brand",
            "name": "Powershotz",
            "logo": "https://data.powershotz.com/logo.png"
        },
        "offers": {
            "@type": "Offer",
            "price": "${price}",
            "availability": "http://schema.org/InStock",
            "priceCurrency": "USD",
            "priceValidUntil": "${date}",
            "url" : "${url}",
            "image": "${image}",
            "name" : "${title}",
            "description": "${description.replace(/"/g, '')}"
        }
        }]`
}

export const structuredOrganization = `[{
        "@context": "https://schema.org",
        "@type": "Organization",
        "url": "https://powershotz.com",
        "logo": "https://data.powershotz.com/logo.png"
        }]`