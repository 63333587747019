import useSWR from 'swr'
import { Link } from 'react-router-dom'
import { SectionLoader } from '../Loaders'
import SmallAddToListBtn from '../buttons/SmAddToListBtn'
import '../../styles/cart.css'
import { fetcher } from '../../utils/functions/fetcher'
import { formatPrice } from '../../utils/functions/formatPrice'
import { useTranslation } from 'react-i18next'

const OrderItem = (props) => {
    const { t } = useTranslation()
    const {
        item,
        isDownloadable,
        isPaid,
        isVerified,
        orderType,
        setHighlightInput,
    } = props

    const url = isPaid && isVerified
        ? `https://data.powershotz.com/php/searchForPaidProduct.php?id=${item}`
        : `https://data.powershotz.com/php/searchForProduct.php?id=${item}`

    const { data, error, isLoading } = useSWR(url, fetcher)

    if (error) return <div className={`card`}>{t('main.error')}</div>
    if (isLoading) return <div className={`card`}><SectionLoader /></div>
    if (!data) return null

    const pathId = data.type === 'clip'
        ? data.id
        : data.title
    const productId = data.pz_code
        ? data.pz_code
        : data.id
    const price = formatPrice(data.price)
    const title = data.title
    let type = data.type === 'photos'
        ? t('main.photos')
        : t('main.video')
    if (orderType === 'dvd') {
        type = 'DVD'
    }
    const downloadLink = data.downloadLink

    const classNm = t('main.download') && t('main.download').length < 10
        ? 'cart_summary'
        : 'cart_summary_int'

    return (
        <div className={classNm}>
            <Link
                className={`cart_productName`}
                to={`/${pathId}`}
            >{title}
            </Link>

            <SmallAddToListBtn
                id={data.id}
                title={data.title}
            />

            <div className={`priceTypeId`}>({type})</div>
            <div className={`priceTypeId`}>#{productId}</div>
            <div className={`priceTypeId`}>${price}</div>

            {isDownloadable && isPaid && isVerified
                ? <a
                    className={`downloadButton`}
                    href={downloadLink}
                    target="_blank"
                    rel="noreferrer"
                    title={t('main.download')}
                >{t('main.download')}
                </a>
                : isDownloadable && isPaid && !isVerified
                    ? <div
                        onClick={() => {
                            window.scrollTo(0, 0)
                            setHighlightInput(true)
                        }}
                        title={type === 'DVD'
                            ? t('order.is_dvd')
                            : t('order.login_to_download')
                        }
                        className={`disabledButPaid`}
                    >{t('order.login')}
                    </div>
                    : <div
                        title={type === 'DVD'
                            ? t('order.is_dvd')
                            : t('order.not_paid')
                        }
                        href="#/"
                        className={`disabledDownloadButton`}
                    >{type === 'DVD' ? 'DVD' : t('order.disabled')}
                    </div>
            }

        </div>
    )
}

export default OrderItem