import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { SectionLoader } from '../Loaders'
import '../../styles/cart.css'
import { formatDate } from '../../utils/functions/formatDate'
import { formatPrice } from '../../utils/functions/formatPrice'

const OtherOrderItem = (props) => {
    const { t } = useTranslation()
    const { item, thisOrderNumber } = props

    const same = thisOrderNumber === item.orderNumber

    if (!item) return <div className={`otherOrderSummary`}><SectionLoader /></div>

    return (
        <div className={`otherOrderSummary`}>
            <p>{formatDate(item.created_at)}</p>
            <p>#{item.orderNumber}</p>
            <p style={styles.unpaid}>{item.paid === "1" ? '' : t('order.no_paid')}<br />
                {item.sent === "1" ? '' : t('order.no_sent')}</p>
            <p>${formatPrice(item.total)}</p>
            <Link to={`/order/${item.orderNumber}`}>
                <button
                    onClick={(e) => window.scrollTo(0, 0)}
                    title={same
                        ? t('order.on_page')
                        : t('order.view_order')
                    }
                    className={same
                        ? `disabledBtn`
                        : `cart_button`
                    }
                    disabled={same
                        ? true
                        : false}
                >{same ? t('order.this_page') : t('order.view')}</button>
            </Link>
        </div >
    )
}

const styles = {
    unpaid: {
        color: 'crimson',
        fontWeight: 'bold',
        marginLeft: 30,
    }
}

export default OtherOrderItem