import { useEffect, useState } from 'react'
import { useScrollRestoration } from 'use-scroll-restoration'
import Card from '../comps/cards/Card'
import { SectionLoader } from '../comps/Loaders'
import SortComponent from '../comps/SortComponent'
import MetaDecorator from '../helmet/MetaDecorator'
import '../styles/forms.css'
import '../styles/products.css'
import { sortProducts } from '../utils/functions/sortProducts'
import { useTranslation } from 'react-i18next'


const Photos = () => {
    const { t } = useTranslation()
    const { ref } = useScrollRestoration("photos", {
        persist: "sessionStorage",
    })

    const [sortType, setSortType] = useState('')
    const [products, setProducts] = useState([])

    useEffect(() => {
        const _sortType = sessionStorage.getItem('sortPhotos')
        _sortType && setSortType(_sortType)
    }, [])

    useEffect(() => {
        const setTheProducts = async () => {
            const data = await sortProducts(sortType, 'photos')
            setProducts(data)
        }

        setProducts([])
        setTheProducts()
    }, [sortType])


    const handleSort = (e) => {
        sessionStorage.setItem('sortPhotos', e.target.value)
        setSortType(e.target.value)
    }

    return (
        <div>
            <MetaDecorator title="Models and Photos" />

            <div className='prod_container'>
                <h1>{t('main.all_photos')}</h1>

                <SortComponent
                    sortType={sortType ? sortType : 'default'}
                    handleSort={handleSort}
                    className='sortPhotos'
                />


                {products.length < 1
                    ? <div><SectionLoader /></div>
                    : <ul ref={ref} style={styles.ul}>
                        {products.map(model => <Card key={model.title} product={model} />)}
                    </ul>
                }

            </div>
        </div>
    )
}

const styles = {
    ul: {
        listStyle: 'none',
        paddingLeft: 0,
    },
    error: {
        textAlign: 'center',
        textTransform: 'uppercase',
        marginTop: 200,
        marginBottom: 100,
    },
}

export default Photos