import { useTranslation } from 'react-i18next'
import Payment from './Payment'
import '../../styles/paymentCard.css'
import PowerCoinz from './PowerCoinz'

const PaymentCard = (props) => {
    const { t } = useTranslation()
    const {
        id,
        coin,
        setCoin = () => { },
        image,
        coinPrice,
        txId,
        setTxId,
        setCoinPrice,
        total,
    } = props

    let coinName = ''
    let message = ''

    if (id === 'CASHAPP') {
        coinName = `Cash App`
    } else if (id === 'ZELLE') {
        coinName = `Zelle`
    } else if (id === 'skrill') {
        coinName = 'Skrill'
    } else if (id === 'coinz') {
        coinName = 'PowerCoinz'
    } else if (id === 'USD') {
        coinName = 'By Mail'
    } else if (id === 'gumroad') {
        coinName = 'Credit Card and Payment Apps'
        message = <div style={styles.gumroadMessage}>
            <p><b>{t('payment.directions')}:</b></p>
            <ul style={styles.ul}>
                <li style={styles.emphasis}>{t('payment.gumroad_first')}</li>

                <li
                    dangerouslySetInnerHTML={{
                        __html: t('payment.gumroad_email')
                    }}
                />

                <li
                    dangerouslySetInnerHTML={{
                        __html: t('payment.gumroad_message')
                    }}
                />
            </ul>
        </div>
    }

    if (id === 'coinz') {
        return (
            <PowerCoinz
                coin={coin}
                setCoin={setCoin}
                cartAmount={total}
            />
        )
    }

    return (
        <div className={`cardMain`} style={styles.main}>
            <div className={`pc_title`}>
                <div className={`coinName`}>
                    <img src={image} className={`pc_logo`} alt={coinName} />
                    <h4>{coinName}</h4>
                </div>
            </div>

            {id === 'gumroad'
                ? message
                : <Payment
                    coin={coin}
                    coinPrice={coinPrice}
                    setCoinPrice={setCoinPrice}
                    total={total}
                    txId={txId}
                    setTxId={setTxId}
                />
            }
        </div >
    )
}

const styles = {
    main: {
        backgroundColor: 'white',
    },
    gumroadMessage: {
        paddingTop: 10,
        paddingRight: 30,
        paddingBottom: 20,
        paddingLeft: 30,
        color: 'black',
        borderTop: '1px solid gray',
    },
    emphasis: {
        color: 'red',
        fontWeight: 'bold',
    },
    ul: {
        lineHeight: 1.5,
        fontSize: '1.1rem'
    }
}

export default PaymentCard