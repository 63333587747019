import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { handleNoOrderFound } from '../comps/toast'
import { fetchOrder } from '../comps/order/fetchOrder'
import '../styles/forms.css'

const Order = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const [orderNumber, setOrderNumber] = useState('')

    useEffect(() => {
        const body = document.getElementById('root')
        body.scrollIntoView(true)
    }, [])

    const handleSubmit = async (e) => {
        e.preventDefault()
        sessionStorage.setItem('orderNumber', orderNumber)
        const isOrder = await fetchOrder(orderNumber)
        if (isOrder) {
            navigate(`/order/${orderNumber}`)
        } else {
            handleNoOrderFound()
        }
    }

    return (
        <div className={`minHeightContainer`}>

            <div className={`mainOrder`}>

                <h1 className='form_heading'>{t('order.enter_order_num')}</h1>

                <form
                    onSubmit={handleSubmit}
                    className={`form`}
                >
                    <input
                        onChange={(e) => {
                            setOrderNumber(e.target.value)
                        }}
                        name='orderNumber'
                        className={`inputOrder`}
                        value={orderNumber}
                        required
                        placeholder={t('order.order_num')}
                    />

                    <div className={`container`}>
                        <button className={`form_button`}>{t('main.submit')}</button>
                    </div>

                </form>

            </div>
        </div>
    )
}

export default Order