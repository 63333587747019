import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useScrollRestoration } from 'use-scroll-restoration'
import NoList from './NoList'
import ListItem from './ListItem'
import { SectionLoader } from '../Loaders'
import '../../styles/favorites.css'
import { getLocalStorage } from '../../utils/localStorage/functions'

const sortList = (_list) => {
    const sort = _list.sort((a, b) => {
        const A = a.id
        const B = b.id
        return A.localeCompare(B)
    })
    return sort.reverse()
}

const FavoriteItemsList = () => {
    const { t, i18n } = useTranslation()
    const { ref } = useScrollRestoration("fav", {
        persist: "sessionStorage",
    })

    const [list, setList] = useState()
    const [action, setAction] = useState(true)

    useEffect(() => {
        const _list = getLocalStorage('list')
        setList(sortList(_list))
    }, [action])

    const s = list ? list.length === 1 ? '' : 's' : ''

    const changeValue = (e) => {
        const val = e.target.value
        let _list = getLocalStorage('list')

        if (val === 'checked') {
            _list = _list.filter(i => i.checked === true)
        } else if (val === 'unchecked') {
            _list = _list.filter(i => i.checked === false)
        }
        setList(sortList(_list))
    }

    return !list
        ? <SectionLoader />
        : <div>
            <h2 className='favHeading'>
                {list.length} {i18n.language === 'en'
                    ? `item${s}`
                    : t('main.items')
                }
                <select
                    name="filterFavorites"
                    onChange={changeValue}
                >
                    <option value='all'>{t('favs.all')}</option>
                    <option value='checked'>{t('favs.checked')}</option>
                    <option value='unchecked'>{t('favs.unchecked')}</option>
                </select>
            </h2>

            {list.length > 0 && <p className='fav_fade'>{t('favs.however')}</p>}

            <ul ref={ref} style={styles.list}>
                {list.length > 0
                    ? list.map((i) => {
                        return (
                            <ListItem
                                key={i.id}
                                item={i}
                                action={action}
                                setAction={setAction}
                            />
                        )
                    })
                    : <NoList />
                }
            </ul>
        </div>
}

const styles = {
    list: {
        listStyleType: 'none',
        padding: 0,
        margin: 0,
    }
}

export default FavoriteItemsList