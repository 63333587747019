const PowershopzHeader = ({ title }) => {
    return (
        <div>
            <h2 className='powershopzHeader'>{title}</h2>
            <a
                href="https://powershopz.com"
                alt="Powershopz"
            >
                <div className='powershopzImage'></div>
            </a>
        </div>
    )
}

export default PowershopzHeader