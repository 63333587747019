import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import CartItemsList from '../cart/CartItemsList'
import Modal from '../Modal'
import '../../styles/scroll.css'
import { getNum } from '../../utils/localStorage/functions'

const ScrollButtons = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [showModal, setShowModal] = useState(false)
  const [listNum, setListNum] = useState(getNum('list'))
  const [cartNum, setCartNum] = useState(getNum('cart'))

  useEffect(() => {
    const handleList = () => setListNum(getNum('list'))
    const handleCart = () => setCartNum(getNum('cart'))

    const ln = setInterval(handleList, 1000)
    const cn = setInterval(handleCart, 1000)

    window.addEventListener('storage', handleList)
    window.addEventListener('storage', handleCart)

    return () => {
      clearInterval(ln)
      clearInterval(cn)
    }
  }, [])

  return (
    <div>
      <div
        className='cart'
        title={t('nav.cart')}
        onClick={() => {
          setShowModal(false)
          navigate('/cart')
        }}
        onMouseEnter={() => cartNum > 0 && setShowModal(true)}
        onMouseLeave={() => setShowModal(false)}
      >
        {cartNum > 0 &&
          <div className={
            cartNum >= 10
              ? 'count10'
              : 'count'}
          >{cartNum}</div>
        }
      </div>

      <Modal
        onClose={() => setShowModal(false)}
        show={showModal}
        title={t('cart.cart_items')}
        showButtons={false}
      >
        <CartItemsList modal={true} />
      </Modal>

      <Link
        to={'/favorites'}
        className='list'
        title={t('main.go_to_favs')}
      >
        {listNum > 0 &&
          <div className={
            listNum >= 10
              ? 'countHeart10'
              : 'countHeart'
          }>{listNum}</div>
        }
      </Link>

      <div
        className='top'
        title={t('nav.top_of_page')}
        onClick={() => {
          window.scrollTo(0, 0)
        }}
      ></div>

      <div
        className='bottom'
        title={t('nav.bottom_of_page')}
        onClick={() => {
          window.scrollTo(0, document.body.scrollHeight)
        }}
      ></div>
    </div>
  )
}

export default ScrollButtons
