import Slider from 'react-slick'
import { useTranslation } from 'react-i18next'
import { SectionLoader } from '../../Loaders'
import { isPhoto } from './isPhoto'
import { isVideo } from './isVideo'
import '../../../styles/carousel.css'

const Carousel = ({ list }) => {
    const { t } = useTranslation()

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        swipeToSlide: true,
        lazyLoad: true,
        adaptiveHeight: true,
        dotsClass: "slick-dots slick-thumb",
        nextArrow: <></>,
        prevArrow: <></>,
        beforeChange: () => {
            // eslint-disable-next-line
            document.querySelectorAll('iframe').forEach(v => { v.src = v.src })
            document.querySelectorAll('video').forEach(v => { v.pause() })
        },
        customPaging: (i) => {
            if (list[i]) {
                if (isPhoto(list[i])) {
                    return (
                        <img
                            src={list[i]}
                            className={`thumbStyle`}
                            alt={`img ${i}`}
                            sizes="(max-width: 768px) 100vw,
                            (max-width: 1200px) 100vw,
                            100vw"
                        />
                    )
                } else {
                    return (
                        <img
                            src={'https://powershopz.com/images/playButton.png'}
                            className={`thumbStyle`}
                            style={{ objectFit: 'cover' }}
                            alt="video"
                            sizes="(max-width: 768px) 100vw,
                            (max-width: 1200px) 100vw,
                            100vw"
                        />
                    )
                }
            } else {
                return <SectionLoader />
            }
        }
    }

    if (!list || list.length === 0) return <SectionLoader />

    return (
        <div className={`carousel_container`}>
            <Slider {...settings}>
                {
                    list.map(img => {
                        if (isPhoto(img)) {
                            return (
                                <img
                                    src={img}
                                    alt={img}
                                    key={img}
                                    width={1000}
                                    height={1000}
                                    quality={100}
                                />
                            )
                        } else if (isVideo(img)) {
                            const ext = img
                                .toString()
                                .split('.')
                                .pop()
                            return (
                                <video
                                    key={img}
                                    controls
                                    width={1000}
                                    height={1000}
                                >
                                    <source
                                        src={img}
                                        type={`video/${ext}`}
                                    />
                                    {t('errors.not_supported')}
                                </video>
                            )
                        } else if (img) {
                            return (
                                <iframe
                                    key={img}
                                    src={img}
                                    title={img}
                                    width={800}
                                    height={400} />
                            )
                        } else {
                            return null
                        }
                    })
                }
            </Slider>
        </div>
    )
}

export default Carousel