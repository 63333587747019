export const getImageLink = (product) => {
    if (!product) return ''

    const link = product && product.type === 'photos'
        ? `https://data.powershotz.com/models2/${product.title.toLowerCase().replace(/ /g, '')}-1.jpg`
        : product && product.image
            ? `https://data.powershotz.com/c4sImages/${product.image}`
            : ''
    return link
}
