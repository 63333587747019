export const isPhoto = (url) => {
    if (!url) return false

    const type = url &&
        url
            .toString()
            .split('.')
            .pop()
            .toLowerCase()

    switch (type) {
        case 'jpg':
            return true
        case 'jpeg':
            return true
        case 'png':
            return true
        case 'gif':
            return true
        case 'webp':
            return true
        case 'tiff':
            return true
        case 'bmp':
            return true
        case 'heif':
            return true
        case 'svg':
            return true
        case 'eps':
            return true
        case 'pdf':
            return true
        case 'psd':
            return true
        case 'ai':
            return true
        case 'xcf':
            return true
        case 'indd':
            return true
        case 'raw':
            return true
        default:
            return false
    }
}
