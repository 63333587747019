import './App.css'
import { Routes, Route } from 'react-router-dom'
import Home from './pages/Home'
import Layout from './comps/elements/Layout'
import Language from './pages/Language'
import USC from './pages/USC'
import Videos from './pages/Videos'
import Search from './pages/Search'
import Photos from './pages/Photos'
import Favorites from './pages/Favorites'
import Faqs from './pages/Faqs'
import Dvds from './pages/Dvds'
import Contact from './pages/Contact'
import Cart from './pages/Cart'
import About from './pages/About'
import Detail from './pages/Detail'
import Order from './pages/Order'
import OrderDetail from './pages/OrderDetail'
import Checkout from './pages/Checkout'
import PowerCoinzScreen from './pages/PowerCoinzScreen'
import CacheBuster from './CacheBuster'

function App() {
  return (
    <Layout>
      <CacheBuster />
      <Routes>
        <Route path='/' element={<Home />} exact />
        <Route path='/language' element={<Language />} />
        <Route path='/usc' element={<USC />} />
        <Route path='/videos' element={<Videos />} />
        <Route path='/photos' element={<Photos />} />
        <Route path='/search' element={<Search />} />
        <Route path='/favorites' element={<Favorites />} />
        <Route path='/faqs' element={<Faqs />} />
        <Route path='/dvds' element={<Dvds />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/cart' element={<Cart />} />
        <Route path='/checkout' element={<Checkout />} />
        <Route path='/about' element={<About />} />
        <Route path='/powercoinz' element={<PowerCoinzScreen />} />
        <Route path='/order' element={<Order />} />
        <Route path='/order/:id' element={<OrderDetail />} />
        <Route path='/:id' element={<Detail />} />
      </Routes>
    </Layout>
  )
}

export default App
