import { formatPrice } from '../../utils/functions/formatPrice'
import { fetchCoinCap } from '../cart/fetchCoinCap'

// TODO: try other apis https://mixedanalytics.com/knowledge-base/top-free-crypto-apis/

export const fetchCoinPrice = async (coinName, setCoinPrice, total) => {

  const coingeckoUrl = `https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=${coinName.toLowerCase()}&order=market_cap_desc&per_page=1&page=1&sparkline=false`

  setCoinPrice('Loading... ')

  const USD = `$${formatPrice(total)}`
  const equiv = `the equivalent of ${USD} in`

  if (
    coinName === 'cash-app' ||
    coinName === 'zelle' ||
    coinName === 'By Mail' ||
    coinName === 'other' ||
    coinName === 'gumroad' ||
    coinName === 'skrill' ||
    coinName === 'coinz'
  ) {
    return setCoinPrice(USD)
  }

  let coinPrices = false
  const altApi = await fetchCoinCap(coinName, total)

  // console.log(coinName, altApi)

  try {
    const res = await fetch(coingeckoUrl, {
      headers: { accept: 'application/json' },
    })

    const response = res
      ? await res.json()
      : false

    const currentPrice = response && response[0]
      ? response[0].current_price
      : false

    coinPrices = currentPrice
      ? total / currentPrice
      : false
  } catch (error) {
    console.log(error.message)
  }

  return altApi
    ? setCoinPrice(altApi)
    : coinPrices
      ? setCoinPrice(coinPrices.toFixed(10))
      : setCoinPrice(equiv)
}
