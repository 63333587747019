export const getProductId = (product) => {
    if (!product) return ''
    const id = product && product.type === 'photos'
        ? product.title
        : product.pz_code &&
            product.pz_code !== '' &&
            product.pz_code !== null &&
            (product.pz_code.includes('PZ') ||
                product.pz_code.includes('VL'))
            ? product.pz_code
            : product.id
    return id
}