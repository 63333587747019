import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import BuyAll from '../buttons/BuyAll'
import AddDvdBtn from '../buttons/AddDvdBtn'
import AddToCartBtn from '../buttons/AddToCartBtn'
import AddToListBtn from '../buttons/AddToListBtn'
import { handleGuarantee } from '../toast'
import '../../styles/products.css'

const ButtonSection = (props) => {
    const { t } = useTranslation()
    const { product, starsIn } = props
    const [action, setAction] = useState(false)

    return (
        <div className={`prod_buttonSection`}>
            <div className={`prod_variableBtn`}>
                {product.pz_code &&
                    <AddDvdBtn id={product.pz_code} />
                }

                {product.type === 'clip' && !product.pz_code &&
                    <div
                        onClick={handleGuarantee}
                        title={t('toast.satisfaction')}
                        className={`prod_emoji`}
                    >
                        😍
                    </div>
                }

                {product.type === 'photos' &&
                    <BuyAll
                        product={product}
                        starsIn={starsIn}
                        action={action}
                        setAction={setAction}
                    />
                }
            </div>

            <div>
                <AddToCartBtn
                    id={product.id}
                    title={product.title}
                    action={action}
                />
            </div>

            <div className={`prod_listIcon`}>
                <AddToListBtn
                    id={product.id}
                    title={product.title}
                    action={action}
                />
            </div>

        </div>
    )
}

export default ButtonSection