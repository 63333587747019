import { useEffect, useState } from 'react'
import { useLocation, Link } from 'react-router-dom'
import banner from '../../images/powershotz_banner_cropped.png'
import BurgerMenu from './BurgerMenu'
import menuItems from './menuItems'
import IconMenuItem from './IconMenuItem'
import ScrollButtons from './ScrollButtons'
import { getNum } from '../../utils/localStorage/functions'
import { isMobile } from 'react-device-detect'

const height = 75

const NavBar = () => {
    const location = useLocation()

    const [num, setNum] = useState(getNum('cart'))
    const [listNum, setListNum] = useState(getNum('list'))
    const [seed, setSeed] = useState(1)


    // if customer hits back button from thank you screen
    const removeAsyncThanks = () => {
        localStorage.removeItem('thanks')
        window.dispatchEvent(new Event('list'))
    }

    // listen for language, cart, and favorites changes
    useEffect(() => {
        const handleLanguage = () => setSeed(Math.random())
        const handleList = () => {
            setListNum(getNum('list'))
            setNum(getNum('cart'))
        }

        window.addEventListener('language', handleLanguage)
        window.addEventListener('list', handleList)

        return () => {
            window.removeEventListener('language', handleLanguage)
            window.removeEventListener('list', handleList)
        }
    }, [])

    useEffect(() => {
        if (!location.pathname.includes('thank_you')) removeAsyncThanks()
    }, [location.pathname])

    return (
        <header key={seed}>
            <BurgerMenu num={num} listNum={listNum} />

            <div style={styles.main}>
                <Link to='/'>
                    <img
                        src={banner}
                        alt='Powershotz'
                        style={styles.banner}
                    />
                </Link>

                {!isMobile &&
                    <div style={styles.icons}>
                        {menuItems &&
                            menuItems.map(i => {
                                return <IconMenuItem
                                    key={i.label}
                                    item={i}
                                    num={num}
                                />
                            })}
                    </div>
                }

            </div>
            <ScrollButtons
                cartNum={num}
                listNum={listNum}
            />
        </header>
    )
}

const styles = {
    main: {
        position: 'fixed',
        top: 0,
        backgroundColor: '#3d1144',
        width: '100%',
        height: height,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    icons: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        paddingRight: 90,
    },
    banner: {
        height: height,
        float: 'left'
    },
}

export default NavBar
