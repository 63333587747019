import { getGifData } from './getGifData'
import { getRandomGifData } from '../functions/getRandomGifData'

export const getPowershopzData = async (setCastingCalls, setDownloadable, setShippable, setGif, setPzcode, setGif2) => {
    const responseCastingCalls = await fetch('https://data.powershotz.com/php/getCastingCalls.php')
    const castingCalls = await responseCastingCalls.json()

    const responseDownloadableProducts = await fetch('https://data.powershotz.com/php/shopz/fetchPublishedDownloadable.php')
    const downloadableProducts = await responseDownloadableProducts.json()

    const responseShippableProducts = await fetch('https://data.powershotz.com/php/shopz/fetchPublishedShippable.php')
    let shippableProducts = await responseShippableProducts.json()

    const gifData = await getGifData('')
    const { gif, pzcode } = getRandomGifData(gifData)
    const { gif: gif2 } = getRandomGifData(gifData)

    castingCalls && setCastingCalls(castingCalls)
    downloadableProducts && setDownloadable(downloadableProducts.slice(0, 50))
    shippableProducts && setShippable(shippableProducts.slice(0, 50))
    gif && setGif(gif)
    pzcode && setPzcode(pzcode)
    gif2 && setGif2(gif2)
}