import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import CartItem from './CartItem'
import { SectionLoader } from '../Loaders'
import '../../styles/cart.css'
import { formatPrice } from '../../utils/functions/formatPrice'
import { getLocalStorage } from '../../utils/localStorage/functions'

const CartItemsList = (props) => {
    const { t, i18n } = useTranslation()
    const { num, modal, action, setAction = () => { } } = props
    const [cart, setCart] = useState()
    const [total, setTotal] = useState(0)

    useEffect(() => {
        setCart(getLocalStorage('cart'))
    }, [])

    const s = num === 1 ? '' : 's'

    if (!cart) return <SectionLoader />

    if (!cart.length) return <p className='emptyCart'>{t('cart.empty')}</p>

    return modal
        ? <div>
            {cart.map((i) => {
                return (
                    <CartItem
                        key={i.id}
                        id={i.id}
                        modal={modal}
                        setTotal={setTotal}
                    />
                )
            })}
            <p className='modalTotal'>
                <span>{t('cart.total')}</span>
                <span>  ${formatPrice(total)}</span>
            </p>
        </div>
        : <div className='cartItemsContainer'>

            {i18n.language === 'en'
                ? <h2 className='cartItemsContainerHeading'>
                    {num} Cart Item{s}
                </h2>
                : <h2 className='cartItemsContainerHeading'>
                    {num} {t('cart.cart_items')}
                </h2>
            }

            {cart.map((i) => {
                return (
                    <CartItem
                        key={i.id}
                        id={i.id}
                        modal={false}
                        action={action}
                        setAction={setAction}
                    />
                )
            })}
        </div>
}

export default CartItemsList