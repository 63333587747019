export const getAllVideos = async () => {
    const url = 'https://data.powershotz.com/php/getPowershotzVideos.php'

    try {
        const res = await fetch(url)
        const data = await res.json()
        return data
    } catch (e) {
        console.info(e.message)
        return false
    }
}