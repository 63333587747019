import { useEffect, useState } from 'react'
import useSWR from 'swr'
import { SectionLoader } from '../Loaders'
import PowerCoinzIframe from './PowerCoinzIframe'
import { useTranslation } from 'react-i18next'
import { handleGenToast } from '../toast'
import { formatPrice } from '../../utils/functions/formatPrice'

const fetcher = (url) => fetch(url).then((res) => res.json())

const PowerCoinzBalance = (props) => {
    const { t } = useTranslation()

    const {
        seeBuy,
        setSeeBuy,
        coin,
        setCoin = () => { },
        cartAmount,
        inputs,
        setInputs,
        thankyou = false,
        screen = false,
    } = props

    const url = `https://data.powershotz.com/php/powercoinz/getAccount.php?email=${inputs.email}&password=${inputs.password}`

    const { data, error, isLoading } = useSWR(url, fetcher, { refreshInterval: 1000 })

    const [message, setMessage] = useState('')

    useEffect(() => {
        if ((data && data.balance >= cartAmount) || thankyou) {
            setMessage(t('coinz.have_enough'))
            setSeeBuy(false)
        } else {
            setMessage(t('coinz.not_enough'))
            setSeeBuy(true)
        }

        if (data && data.balance) {
            setCoin(values => ({
                ...values,
                balance: data.balance,
                email: data.email,
                password: data.password
            }))
            setInputs(values => ({
                ...values,
                balance: data.balance
            }))
        }
        // eslint-disable-next-line
    }, [data, cartAmount, thankyou])

    const handleSeeBuy = () => {
        setSeeBuy(true)
        handleGenToast(t('coinz.please_wait'))
    }

    if (error) return <h2 style={{ textAlign: 'center' }}>{t('admin.try_again')}</h2>
    if (!data || isLoading) return <SectionLoader />

    return (
        <div style={
            seeBuy
                ? { ...styles.main, marginBottom: -60 }
                : styles.main
        }
            className='dark_coinz'
        >
            <h2 style={styles.balance}>{t('coinz.coinz_balance')} {formatPrice(parseFloat(data.balance))}</h2>

            <p style={styles.equiv}>{t('coinz.one_one')}</p>

            {!thankyou && !screen &&
                <h3 style={
                    message === t('coinz.not_enough')
                        ? { ...styles.message, color: 'red' }
                        : { ...styles.message, color: 'green' }
                }
                >{message}</h3>
            }

            {
                seeBuy
                    ? <PowerCoinzIframe
                        coin={coin}
                        thankyou={thankyou}
                        setSeeBuy={setSeeBuy}
                        screen={screen}
                    />
                    : <button
                        style={styles.button}
                        onClick={handleSeeBuy}
                    >
                        {t('coinz.buy_coinz')}
                    </button>
            }
        </div >
    )
}

const styles = {
    main: {
        textAlign: 'center',
        marginBottom: 0,
    },
    message: {
        width: '90%',
        margin: 'auto',
        marginTop: 20,
        marginBottom: 0,
        fontWeight: 'bold'
    },
    balance: {
        margin: 0,
        fontWeight: 'bold',
    },
    button: {
        marginTop: 25,
        paddingLeft: 15,
        paddingRight: 15,
        textTransform: 'capitalize'
    },
    equiv: {
        margin: 5,
        fontSize: '0.9rem'
    },
}

export default PowerCoinzBalance