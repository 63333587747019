const getCCPaymentLink = (totalAmount, setAddy) => {
    const url = `https://powershopz.com/php/orders/getCCLink.php?amount=${totalAmount}`

    fetch(url)
        .then(res => res.json())
        .then(data => {
            const url = data.find(i => i.link.includes('lemonsqueezy')).link
            setAddy(url)
        })
        .catch(e => console.error(e.message))
}

export default getCCPaymentLink