import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { isMobile } from 'react-device-detect'
import { isBanned } from './banned'
import { handleGenToast, handleError } from '../toast'

const EnterEmailToPay = (props) => {
    const { t } = useTranslation()
    const { order, setIsVerified } = props

    const [email, setEmail] = useState('')
    // TODO: translate
    const [message, setMessage] = useState('Enter your email to pay')

    const handleSubmit = async (e) => {
        e.preventDefault()

        const url = `https://data.powershotz.com/php/verifyEmail.php?orderNumber=${order.orderNumber}`

        fetch(url)
            .then(res => res.json())
            .then(data => {
                return data.email.toLowerCase().trim()
            })
            .then((_orderEmail) => {
                if (_orderEmail === email.toLowerCase().trim()) {
                    setIsVerified(true)
                    setMessage(t('main.success'))
                    sessionStorage.setItem('email', email)
                    handleGenToast(t('main.logged_in'))
                } else {
                    setIsVerified(false)
                    setMessage(t('main.invalid_email'))
                    sessionStorage.removeItem('email')
                    handleError(t('main.invalid_email'))
                }
            })
            .catch(e => console.error(e))
    }

    const handleEmail = async (email) => {
        setEmail(email)
        setMessage('')
        await isBanned(email, setEmail)
    }

    return (
        <div>
            <form
                onSubmit={handleSubmit}
                style={styles.form}
            >
                <input
                    onChange={(e) => handleEmail(e.target.value)}
                    name='email'
                    type='email'
                    value={email}
                    placeholder={message}
                    style={styles.input}
                    title={message}
                />

                <button type='submit'>{t('main.pay_now')}</button>
            </form>
            <p style={message === 'success!' || message === 'Enter your email to pay' ? styles.success : styles.message}>{message}</p>
        </div>
    )
}

const styles = {
    form: {
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    input: {
        fontSize: isMobile ? '1rem' : '1.1rem',
        flex: 1,
        height: 30,
        borderRadius: 5,
        paddingLeft: 10
    },
    message: {
        color: 'red'
    },
    success: {
        color: 'lime'
    }
}

export default EnterEmailToPay