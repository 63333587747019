import '../styles/loader.css'

export const AppLoader = () => {
    return (
        <section className={`appLoader`}>
            <div className={`bouncingLoader`}>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </section>
    )
}

export const SectionLoader = () => {
    return (
        <section className={`sectionLoader`}>
            <div className={`bouncingLoader`}>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </section>
    )
}