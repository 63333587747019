import i18n from '../../i18n/i18n'

export const getPlaceholderLabelShow = (data) => {
    let placeholder = i18n.t('tx_id.tx_id')
    let label = i18n.t('tx_id.tx_id_faster')
    let showInput = true
    let isCoin = true
    let showFasterProcessing = true

    if (
        data.name === 'cash-app' ||
        data.coinName === 'cash-app'
    ) {
        placeholder = i18n.t('payment.your_cashtag')
        label = i18n.t('payment.cashtag_faster')
        isCoin = false
    } else if (data.name === 'skrill' || data.coinName === 'skrill') {
        placeholder = i18n.t('main.name')
        label = i18n.t('main.name')
        isCoin = false
    } else if (
        data.order_type === 'dvd' &&
        (data.coinName === 'zelle' ||
            data.coinName === 'By Mail' ||
            data.coinName === 'gumroad')
    ) {
        showInput = false
        label = ''
        isCoin = false
        showFasterProcessing = false
    } else if (
        data.name === 'By Mail' ||
        data.coinName === 'By Mail' ||
        data.name === 'zelle' ||
        data.coinName === 'zelle' ||
        data.coinName === 'gumroad' ||
        data.name === 'gumroad'
    ) {
        placeholder = i18n.t('main.name')
        label = i18n.t('payment.name_faster')
        isCoin = false
    }

    return { placeholder, label, showInput, isCoin, showFasterProcessing }
}
