import {
    MdHome,
    MdOutlineContactless,
    MdVideoLibrary,
    MdPhotoLibrary,
    MdOfflineBolt,
} from "react-icons/md"
import {
    FaQuestionCircle,
    FaInfoCircle,
    FaSearch,
    FaGlobe,
    FaHeart,
} from "react-icons/fa"
import { TbReceipt2 } from "react-icons/tb"
import { BsCart } from "react-icons/bs"
import { GiCompactDisc } from "react-icons/gi"

const menuItems = [
    {
        label: `home`,
        url: `/`,
        icon: <MdHome />
    },
    {
        label: `contact`,
        url: `/contact`,
        icon: <MdOutlineContactless />
    },
    {
        label: `videos`,
        url: `/videos`,
        icon: <MdVideoLibrary />
    },
    {
        label: `photos`,
        url: `/photos`,
        icon: <MdPhotoLibrary />
    },
    {
        label: `cart`,
        url: `/cart`,
        icon: <BsCart />
    },
    {
        label: `favorites`,
        url: `/favorites`,
        icon: <FaHeart />
    },
    {
        label: `dvds`,
        url: `/dvds`,
        icon: <GiCompactDisc />
    },
    {
        label: `search`,
        url: `/search`,
        icon: <FaSearch />
    },
    {
        label: `about`,
        url: `/about`,
        icon: <FaInfoCircle />
    },
    {
        label: `faqs`,
        url: `/faqs`,
        icon: <FaQuestionCircle />
    },
    {
        label: `language`,
        url: `/language`,
        icon: <FaGlobe />
    },
    {
        label: `orders`,
        url: `/order`,
        icon: <TbReceipt2 />
    },
    {
        label: `powercoinz`,
        url: `/powercoinz`,
        icon: <MdOfflineBolt style={{ transform: 'rotate(20deg)' }} />
    },

]

export default menuItems