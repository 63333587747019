import { useTranslation } from 'react-i18next'

const InfoSection = (props) => {
    const { t } = useTranslation()
    const { product } = props

    return (
        <div className={`prod_infoSection`}>
            <p className={`prod_id`}>#
                {
                    product.pz_code
                        ? product.pz_code
                        : product.id
                }
            </p>
            <p className={`prod_price`}>${product.price}</p>
            <p className={`prod_num`}>{product.length} {product.type === 'clip' ? t('main.minutes') : t('main.photos')}</p>
        </div>
    )
}

export default InfoSection