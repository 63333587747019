import { useEffect, useState } from 'react'
import { MdLanguage } from "react-icons/md"
import { useTranslation } from 'react-i18next'
import MetaDecorator from '../helmet/MetaDecorator'

const locales = {
    cn: { title: '普通话 (cn)' }, //mandarin
    de: { title: 'Deutsch (de)' },
    en: { title: 'English (en)' },
    es: { title: 'Español (es)' },
    fr: { title: 'Français (fr)' },
    it: { title: 'Italiano (it)' },
    // iw: { title: 'עִברִית' }, //hebrew
    ja: { title: '日本語 (ja)' }, //japanese
    // ko: { title: '한국인 (ko)' }, //korean
    // nl: { title: 'Nederlands (nl)' }, //dutch
    // pl: { title: 'Polski (pl)' }, //polish
    // ro: { title: 'Română (ro)' }, //romanian
    ru: { title: 'Русский (ru)' } //russian
}

const Language = () => {
    const { t, i18n } = useTranslation()

    const [lan, setLan] = useState(i18n.language)

    useEffect(() => {
        const body = document.getElementById('root')
        body.scrollIntoView(true)
        setLan(i18n.language)
    }, [i18n.language])

    return (
        <div style={styles.main}>

            <MetaDecorator title="Change Language" />

            <h1 style={styles.heading}>
                <MdLanguage style={styles.icon} size={40} />
                {t('nav.change_language')}
            </h1>

            {Object.keys(locales).map((locale) => (
                <div
                    key={locale}
                    style={
                        lan === locale || lan.includes(locale)
                            ? styles.active
                            : styles.li
                    }
                    onClick={() => {
                        i18n.changeLanguage(locale)
                        setLan(locale)
                        window.dispatchEvent(new Event('language'))
                        window.history.go(-1)
                    }}>
                    {locales[locale].title}
                </div>
            ))}
        </div >
    )
}

const styles = {
    main: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 30,
    },
    heading: {
        fontWeight: 'bold',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        fontSize: '1.75rem'
    },
    icon: {
        marginRight: 8,
    },
    li: {
        width: 50,
        backgroundColor: 'var(--buttonColor)',
        color: 'snow',
        padding: 10,
        cursor: 'pointer',
        borderRadius: 5,
        borderWidth: 0,
        textTransform: 'uppercase',
        margin: 10,
        minWidth: 150,
        textAlign: 'center',
    },
    active: {
        fontWeight: 'bold',
        fontSize: 'large',
        width: 50,
        backgroundColor: 'var(--buttonColor)',
        color: 'limegreen',
        padding: 10,
        cursor: 'pointer',
        borderRadius: 5,
        borderWidth: 0,
        textTransform: 'uppercase',
        margin: 10,
        minWidth: 150,
        textAlign: 'center',
    },
}

export default Language