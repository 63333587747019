export const updateTransactionId = async (orderId, paymentId) => {
    const data = {
        orderId: orderId,
        paymentId: paymentId,
    }

    const url = 'https://data.powershotz.com/php/updatePaymentId.php'
    let message = 'test'

    await fetch(url, {
        method: 'POST',
        body: JSON.stringify(data),
    })
        .then(() => {
            message = 'Success!'
        })
        .catch((e) => {
            alert(`Error updating paymentId\n${e.message}`)
            message = e.message
        })
    return message
}
