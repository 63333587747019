import { useEffect, useState } from 'react'
import { SectionLoader } from './Loaders'

const TimedLoader = (props) => {
    const [loader, setLoader] = useState(
        <div className={`prod_notFound`}>
            <SectionLoader />
        </div>
    )

    const title = props.title ? props.title : 'Product not found'
    const message = props.message ? props.message : 'please check the url...'

    useEffect(() => {
        window?.scrollTo(0, 0)

        let timer = setTimeout(() => setLoader(
            <h1 className={`prod_notFound`}>{title}
                <br />
                <span className={`prod_notFoundSmall`}>{message}</span>
                <br />
                {props.children}
            </h1>
        ), 5000)

        return () => clearTimeout(timer)
        // had no dependency array
    }, [message, title, props.children])

    return <div>{loader}</div>
}

export default TimedLoader