export const getStarsInData = async (id, productId) => {
    const lcId = id.toString().toLowerCase()
    const encId = encodeURIComponent(lcId)
    const url = `https://data.powershotz.com/php/getStarsInData.php?id=${encId}&productId=${productId}`

    try {
        const res = await fetch(url)
        const data = await res.json()
        return data
    } catch (e) {
        console.log(e)
        return false
    }
}