import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import ContactForm from '../comps/contact/ContactForm'
import MetaDecorator from '../helmet/MetaDecorator'
import '../styles/contact.css'

const Contact = () => {
    const { t } = useTranslation()

    useEffect(() => {
        const body = document.getElementById('modal-root')
        body.scrollIntoView(true)
    }, [])

    return (
        <div id="contact">
            <div className={`contact_minHeightContainer`}>

                <MetaDecorator
                    title="Contact"
                    description="Contact Powershotz"
                />

                <h1 className='contact_heading'>{t('nav.contact_us')}</h1>

                <ContactForm />
            </div>
        </div>
    )
}

export default Contact