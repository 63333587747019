import { useTranslation } from 'react-i18next'
import DvdItem from './DvdItem'
import '../../styles/cart.css'

const DvdItemsList = (props) => {
    const { t, i18n } = useTranslation()
    const { dvds, setDvds } = props

    const s = dvds ? dvds.length === 1 ? '' : 's' : ''

    return (
        <div className='cartItemsContainer'>
            {i18n.language === 'en' ?
                <h2 className='cartItemsContainerHeading'>
                    {dvds.length} DVD{s}
                </h2>
                : <h2 className='cartItemsContainerHeading'>
                    {dvds.length} {t('nav.dvds')}
                </h2>
            }

            {dvds.map((i) => {
                return (
                    <DvdItem
                        key={i}
                        id={i}
                        setDvds={setDvds}
                    />
                )
            })}

        </div>
    )
}

export default DvdItemsList