import { placeOrder } from '../../utils/sql/placeOrder'
import { keepCheckedItems } from '../../utils/localStorage/functions'
import { handleThankYou } from '../toast'

const clearCart = () => {
    sessionStorage.removeItem('order')
    keepCheckedItems('cart')
}

export const runPlaceOrder = (data, navigate) => {
    placeOrder(data)
        .then(() => handleThankYou())
        .then(() => clearCart())
        .then(() => sessionStorage.setItem('orderNumber', data.orderNumber))
        .then(() => sessionStorage.setItem('temp_email', data.email))
        .then(() => localStorage.removeItem('cartOrderNumber'))
        .then(() => navigate(`/order/${data.orderNumber}`))
        .catch(e => console.log(e))
}
