import { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import '../styles/modal.css'

const Modal = ({
    show,
    onClose,
    children,
    title,
    buttonText = '',
    link = '',
    showButtons = true
}) => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const [isBrowser, setIsBrowser] = useState(false)

    useEffect(() => {
        setIsBrowser(true)
    }, [])

    const handleCloseClick = (e) => {
        e.preventDefault()
        onClose()
    }

    const goTo = () => {
        onClose()
        navigate(link)
    }

    const modalContent = show ? (
        <div className='modalOverlay'>
            <div className='modal'>
                <div className='modalHeader'>

                    {title &&
                        <h3 className='modalTitle'>{title}</h3>
                    }

                    {showButtons &&
                        <AiOutlineCloseCircle
                            size={30}
                            className='close'
                            onClick={handleCloseClick}
                        />
                    }
                </div>

                <div className='modalBody'>{children}</div>

                {showButtons && <div className='buttonContainer'>

                    <button
                        onClick={handleCloseClick}
                    >
                        {t('main.close')}
                    </button>

                    {buttonText !== "" &&
                        <button onClick={goTo}>
                            {buttonText}
                        </button>
                    }
                </div>}
            </div>
        </div>
    ) : null

    if (isBrowser) {
        return ReactDOM.createPortal(
            modalContent,
            document.getElementById("modal-root")
        )
    } else {
        return null
    }
}

export default Modal