import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Footer from '../comps/elements/Footer'
import MetaDecorator from '../helmet/MetaDecorator'
import '../styles/about.css'

const About = () => {
    window.scrollTo(0, 0)
    const { t, i18n } = useTranslation()

    return (
        <div>
            <MetaDecorator title="About" />

            <div className={`main`}>
                <h1>{t('about.about_you')}</h1>
                <h2>{t('about.terms')}</h2>
                <p className={`uppercase`}>{t('about.terms_text')}</p>

                <h1>{t('about.about_us')}</h1>
                <h2>{t('about.phil')}</h2>
                <p>{t('about.p1')}</p>
                <p>{t('about.p2')}</p>
                <p>{t('about.p3')}</p>
                <p>{t('about.p4')}</p>
                <p>{t('about.p5')}</p>
                <p> <span className={`uppercase`}>{t('about.warning')}</span> {t('about.p6')}</p>

                {i18n.language === 'en'
                    ? <p>This site complies with the record-keeping requirements of <Link to={"/usc"}>USC 2257</Link>. This means that all of the models who have posed for this site were 18 years of age or older on the date of shooting and that a minimum set of government stipulated age verifications and methods to contact the model and producer have been met.</p>
                    : <p>{t('about.p7')}</p>
                }

                <h1>{t('about.privacy')}</h1>

                <p>{t('about.privacy_text')}</p>

                <h2>{t('about.minors')}</h2>
                <p>{t('about.minors_text')}</p>

                <h2>{t('about.underage')}</h2>

                <p>{t('about.underage2')}</p>

                <h2>{t('about.id_info')}</h2>
                <p>{t('about.id_info_text')}</p>

                <h2>{t('about.sharing')}</h2>
                <p>{t('about.sharing_text')}</p>

                <h2>{t('about.third_party')}</h2>
                <p>{t('about.third_party_text')}</p>

                <h2>{t('about.changes')}</h2>
                <p>{t('about.changes_text')}</p>

                <h2>{t('about.questions')}</h2>
                {i18n.language === 'en'
                    ? <p><Link to='/contact'><b>{t('nav.contact_us')}</b></Link> or write to: DesignTech; PO Box 141; Wildwood, MO 63038.</p>

                    : <p>{t('about.questions_text')}</p>

                }

                <h1>{t('about.guarantee')}</h1>
                <p>{t('about.guarantee_text')}</p>

            </div>

            <div style={styles.spacer}></div>

            <Footer />
        </div>
    )
}

const styles = {
    spacer: {
        height: 50,
    }
}

export default About