export const getRandomGifData = (gifData) => {
    const num = Math.floor(Math.random() * gifData.length)
    const randomGif = gifData[num]
    const gif = 'https://data.powershotz.com/gif/' + randomGif
    let pzcode

    if (randomGif.startsWith('p')) {
        pzcode = randomGif.substring(0, 6).toUpperCase()
    } else if (randomGif.startsWith('v')) {
        pzcode = randomGif.substring(0, 5).toUpperCase()
    } else {
        pzcode = randomGif.substring(0, 8)
    }

    return { gif, pzcode }
}