import { useTranslation } from 'react-i18next'
import FavoriteItemsList from '../comps/favorites/FavoriteItemsList'
import MetaDecorator from '../helmet/MetaDecorator'
import '../styles/favorites.css'

const Favorites = () => {
    const { t } = useTranslation()

    return (
        <div className='fav_main'>

            <MetaDecorator title="Favorites" />

            <h1 className='fav_heading'>{t('favs.favorites')}</h1>

            <FavoriteItemsList />

        </div>
    )
}

export default Favorites