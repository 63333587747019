import Highlighter from "react-highlight-words"

export const highlight = (searchTerm = '', text = '') => {

    let terms = []
    const split = searchTerm.split(' ')
    split.forEach(element => {
        terms.push(element)
    })

    return <Highlighter
        highlightStyle={{ backgroundColor: 'rgba(255,215,0, 0.75)' }}
        searchWords={terms}
        textToHighlight={text}
    />
}
