import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useScrollRestoration } from 'use-scroll-restoration'
import Card from '../comps/cards/Card'
import { SectionLoader } from '../comps/Loaders'
import SortComponent from '../comps/SortComponent'
import MetaDecorator from '../helmet/MetaDecorator'
import '../styles/forms.css'
import '../styles/products.css'
import { sortProducts } from '../utils/functions/sortProducts'

// SETS
// (74)vl $1480 - $20/per video
// (74)vl $2219.26 - $29.99/per video

// (126)pzv $2205 - $17.50/per video
// (126)pzv $3148.74 - $24.99/per video

// (low) all $3685 - round to $3500
// (high) all $5368

// photos
// 202 sets = $787.62
// 393.81 at 50% off

////////////
////////////
// DEALERS /
////////////
////////////
// 200 videos at $25 per video = $5000
// minimum 50 videos for bulk rate
////////////
////////////

// If you want downloads, we offer 50% off sets.
// For VL series in bulk (74 videos), it’s $20 per video or $1480 for the set.
// For PZV series in bulk (126 videos), it’s $17.50 per video or $2205 for the set.
// For Photo sets (202 sets), it’s $394 (usually $787.62)).
// But, for both sets we round $3685 down to $3500.
// For both sets and photos it’s $3800.

// Some people prefer their sets on DVDs and/or in digital format on an external hard drive.
// Additional charges are determined by the current costs of shipping and materials (usually about $100 - 200).
// Let me know if you’d like me to calculate any of the above formats.

const Videos = () => {
    const { t } = useTranslation()
    const { ref } = useScrollRestoration("videos", {
        persist: "sessionStorage",
    })

    const [sortType, setSortType] = useState('')
    const [products, setProducts] = useState([])

    useEffect(() => {
        const _sortType = sessionStorage.getItem('sortVideos')
        _sortType && setSortType(_sortType)
    }, [])

    useEffect(() => {
        const setTheProducts = async () => {
            const data = await sortProducts(sortType, 'clip')
            setProducts(data)
        }

        setProducts([])
        setTheProducts()
    }, [sortType])

    const handleSort = (e) => {
        sessionStorage.setItem('sortVideos', e.target.value)
        setSortType(e.target.value)
    }

    return (
        <div>
            <MetaDecorator title="Videos" />

            <div className='prod_container'>
                <h1>{t('main.all_videos')}</h1>

                <SortComponent
                    sortType={sortType ? sortType : 'default'}
                    handleSort={handleSort}
                    className='sortVideos'
                />

                {products.length < 1
                    ? <div><SectionLoader /></div>
                    : <ul ref={ref} style={styles.ul}>
                        {products.map(vid => <Card key={vid.id} product={vid} />)}
                    </ul>
                }

            </div>
        </div>
    )
}

const styles = {
    ul: {
        listStyle: 'none',
        paddingLeft: 0,
    },
    error: {
        textAlign: 'center',
        textTransform: 'uppercase',
        marginTop: 200,
        marginBottom: 100,
    },
}

export default Videos