import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const VideosPhotos = (props) => {
    const { t } = useTranslation()

    return (
        <div className='videoPhotoContainer'>
            <div className='videoPhoto'>
                <h2>
                    <span className='powershotzFont'>Powershotz</span><br />
                    {t('nav.videos_clips')}
                </h2>

                <p>{t('home.watch_previews')} <Link to={"/dvds"}>{t('nav.dvds')}</Link></p>
                <Link to={"/videos"}><button>{t('home.see_all_videos')}</button></Link>
            </div>

            <div className='videoPhoto'>
                <h2>
                    <span className='powershotzFont'>Powershotz</span><br />
                    {t('nav.models_photos')}
                </h2>

                <p>{t('home.see_models')} </p>
                <Link to={"/photos"}><button>{t('home.see_all_photos')}</button></Link>
            </div>
        </div>
    )
}

export default VideosPhotos