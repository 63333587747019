import { useTranslation } from 'react-i18next'
import { BsCartPlusFill, BsCartDash } from 'react-icons/bs'
import { handleAddToCart, handleRemoveFromToCart } from '../toast'
import '../../styles/products.css'
import { addItem, removeItem } from '../../utils/localStorage/functions'

const SmAddToCartBtn = ({ id, title, isItInCart, setIsItInCart }) => {
    const { t } = useTranslation()

    const titleMessage = isItInCart
        ? t('main.remove_from_cart')
        : t('main.add_to_cart')

    const handleClick = () => {
        if (isItInCart) {
            handleRemoveFromToCart(title)
            removeItem('cart', id, setIsItInCart)
            setIsItInCart(false)
        } else {
            handleAddToCart(title)
            addItem('cart', id, setIsItInCart)
            setIsItInCart(true)
        }
    }

    const icon = isItInCart
        ? <BsCartDash
            className='prod_cardIcon'
            title={titleMessage}
            onClick={handleClick}
            size={22}
        />
        : <BsCartPlusFill
            className='prod_cardIcon'
            title={titleMessage}
            onClick={handleClick}
            size={22}
        />

    return (
        <span>{icon}</span>
    )
}

export default SmAddToCartBtn