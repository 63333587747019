import { useTranslation } from 'react-i18next'

const WhatIsTxId = (props) => {
    const { t } = useTranslation()

    return (
        <div onClick={() => props.setShowModal(false)}>
            <h3>{t('tx_id.what')}</h3>
            <p>{t('tx_id.what_text')}</p>

            <h3>{t('tx_id'.how)}</h3>
            <p>{t('tx_id.generally')}</p>
            <ol>
                <li>{t('tx_id.li_1')}</li>
                <li>{t('tx_id.li_2')}</li>
                <li>{t('tx_id.li_3')}</li>
                <li>{t('tx_id.li_4')}</li>
                <li>{t('tx_id.li_5')}</li>
                <li>{t('tx_id.li_6')}</li>
            </ol>
        </div>
    )
}

export default WhatIsTxId