import '../../styles/random.css'
import { SectionLoader } from '../Loaders'
import { Link } from 'react-router-dom'

const RandomGif = (props) => {
    const { gif, pzcode } = props

    return gif && pzcode ? (
        <figure className='random_container'>
            <img
                src={gif}
                alt={pzcode}
                className='random_image'
            />
            <figcaption>
                <Link to={`/${pzcode}`}>{pzcode}</Link>
            </figcaption>
        </figure>
    )
        : <SectionLoader />
}

export default RandomGif
