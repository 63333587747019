import { ImNewTab } from "react-icons/im"
import { AiOutlineCopy } from 'react-icons/ai'
import { isMobile } from 'react-device-detect'
import CopyToClipboard from 'react-copy-to-clipboard'
import { handleToast } from './toast'
import '../styles/cart.css'

const CopyComp = ({ text, textToCopy, dark = false }) => {
    let textToDisplay = text &&
        typeof text === 'string' &&
        text.includes('https://')
        ? <div title={text} style={styles.link}>
            <a
                href={text}
                className={dark ? `dark` : `wrap`}
                target='_blank'
                rel="noopener noreferrer"
                title={text}
            >
                {text}
                <ImNewTab style={styles.icon} />
            </a>

        </div>
        : <CopyToClipboard
            text={textToCopy}
            onCopy={handleToast}
        >
            <span className={dark ? `dark` : `wrap`}>{text}
                <AiOutlineCopy className={`copy`} />
            </span>
        </CopyToClipboard>

    return (
        <span style={styles.span}
            className={`copyText`}
            title="copy to clipboard"
        >
            {textToDisplay}
        </span>
    )
}

const styles = {
    link: {
        alignItems: 'center',
        display: 'flex'
    },
    icon: {
        marginLeft: 10,
    },
    span: {
        fontSize: isMobile
            ? '1.2rem'
            : '1.5rem',
        wordBreak: 'break-word',
        fontWeight: 'bold',
        flex: 30,
    }
}

export default CopyComp