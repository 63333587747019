import { ParallaxProvider } from 'react-scroll-parallax'
// import Footer from './Footer'
import NavBar from './NavBar'
import { ToastComp } from '../toast'
import MetaDecorator from '../../helmet/MetaDecorator'

// TODO: add bouncing balls for fallback
const Layout = ({ children }) => {
    return (
        <ParallaxProvider>
            <MetaDecorator />
            <div id='modal-root' style={styles.container}>
                <NavBar />
                <ToastComp />
                <div style={styles.inner}>
                    <main style={styles.content}>{children}</main>
                </div>
                {/* <Footer /> */}
            </div>
        </ParallaxProvider>
    )
}

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        justifyContent: 'space-between',
    },
    inner: {
        flexGrow: 1,
        paddingBottom: 50,
    },
    content: {
        margin: '100px auto 50px auto',
        maxWidth: '100vw',
        padding: '2.5rem 1 rem'
    }
}

export default Layout