export const findProduct = async (id) => {
    const pid = encodeURIComponent(id)
    const url = `https://data.powershotz.com/php/searchForProduct.php?id=${pid}`

    try {
        const res = await fetch(url)
        const product = await res.json()
        return product
    } catch (e) {
        console.info(e.message)
        return false
    }
}