import 'animate.css'
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import HeaderImg from './HeaderImg'

const Header = (props) => {
    const { t } = useTranslation()
    const { gif } = props

    return (
        <div className='headerMain'>
            <HeaderImg gif={gif} />

            <h1 className="animate__animated animate__zoomInDown animate__fast logoText" >
                {t('home.welcome')} <span className='welcome'>Powershotz!</span>
            </h1>

            <h2
                className='storeDescription'
                style={styles.desc}>
                {t('home.original')}
            </h2>
        </div>
    )
}

const styles = {
    desc: {
        width: isMobile ? '70%' : '70%',
        margin: 'auto',
        textAlign: 'center',
    }
}

export default Header
