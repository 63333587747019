import { useEffect } from 'react'
import '../../styles/cart.css'

const CoinPicker = ({ coins, coin, setCoin, setError }) => {
    const blank = [{}]
    let coinList = blank.concat(coins)

    const changeValue = (e) => {
        const val = e.target.value
        const picked = coinList.find(i => i.name === val)
        setError('')
        setCoin({ ...picked })
    }

    useEffect(() => {
        if (!coin.name) setCoin('none')
    }, [coin, setCoin])

    return (
        <div className={`coinPicker`}>
            <select
                name="coinPicker"
                className={`coinPickerSelect`}
                onChange={changeValue}
                value={coin.name ? coin.name : coinList[0]}
            >
                {coinList.map((item) => {
                    return (
                        <option
                            key={item.name ? item.name : Math.random()}
                            value={item.name}
                        >{item.label}</option>
                    )
                })}
            </select>
        </div>
    )
}

export default CoinPicker