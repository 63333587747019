import { useTranslation } from 'react-i18next'
import Application from './Application'
import PowershopzHeader from './PowershopzHeader'

const ScriptOrModelApp = () => {
    const { t } = useTranslation()

    return (
        <div className='cardGridApp' >
            <PowershopzHeader title={t('home.application')} />
            <Application />
        </div>
    )
}

export default ScriptOrModelApp