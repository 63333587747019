import { useEffect, useState } from 'react'
import useSWR from 'swr'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { isMobile } from 'react-device-detect'
import { MdOfflineBolt } from "react-icons/md"
import TimedLoader from '../comps/TimedLoader'
import { handleGenInfo } from '../comps/toast'
import { SectionLoader } from '../comps/Loaders'
import EnterEmail from '../comps/order/EnterEmail'
import OtherOrders from '../comps/order/OtherOrders'
import PowerCoinz from '../comps/payments/PowerCoinz'
import PaymentStatic from '../comps/order/PaymentStatic'
import OrderItemsList from '../comps/order/OrderItemsList'
import TotalAndOrderNum from '../comps/order/TotalAndOrderNum'
import MetaDecorator from '../helmet/MetaDecorator'
import '../styles/cart.css'
import { fetcher } from '../utils/functions/fetcher'

const OrderDetail = () => {
    const { t, i18n } = useTranslation()

    const { pathname } = useLocation()
    const id = pathname.replace('/order/', '')

    const [isVerified, setIsVerified] = useState(false)
    const [highlightInput, setHighlightInput] = useState(false)
    const [showCoinz, setShowCoinz] = useState(false)

    const handleToggleCoinz = () => setShowCoinz(!showCoinz)

    const url = `https://data.powershotz.com/php/getOrder.php?orderNumber=${id}`

    const { data, error, isLoading } = useSWR(url, fetcher, { refreshInterval: 5000 })

    useEffect(() => {
        if (!data) return
        setIsVerified(false)
        const emailUrl = `https://data.powershotz.com/php/verifyEmail.php?orderNumber=${id}`

        fetch(emailUrl)
            .then(res => res.json())
            .then(data => {
                const _orderEmail = data.email.toLowerCase().trim()
                const _savedEmail = sessionStorage.getItem('email')
                const temp_email = sessionStorage.getItem('temp_email')
                _orderEmail === _savedEmail || temp_email
                    ? setIsVerified(true)
                    : setIsVerified(false)
            })
    }, [data, id])

    const isPaid = data && data.paid === '0' && data.sent === '0'
        ? false
        : true

    let isDownloadable = false
    let type = 'DVD'
    if (data && data.order_type === 'download') {
        isDownloadable = true
        type = ''
    }

    const logout = () => {
        setIsVerified(false)
        sessionStorage.setItem('verified', 'false')
        sessionStorage.removeItem('email')
        handleGenInfo(t('main.logged_out'))
    }

    if (error) return <h1 style={styles.error}>{t('main.error')}</h1>
    if (isLoading) return <div style={styles.error}><SectionLoader /></div>
    if (!data) return <TimedLoader
        title={t('toast.order_not_found')}
        message={t('main.check_order_number')}
    >
        <Link to={'/order'}>
            <button>{t('main.try_again')}</button>
        </Link>
    </TimedLoader>

    return (
        <div className={`cart_main`}>

            <MetaDecorator
                title={`${id}`}
                description="View your order status and download Powershotz products."
            />

            <div className={`yourOrderHeading`}>
                {i18n.language === 'en'
                    ? <h1 className='thankYou'>Thank you for Your {type} Order</h1>
                    : <h1 className='thankYou'>{t('order.thank_you')}</h1>
                }

                {isPaid &&
                    <p className={`paidCoin`} style={styles.paid}> ({t('cart.paid')} {data.coinAbbr.toUpperCase() === 'GUMROAD' ? 'CC' : data.coinAbbr.toUpperCase()})</p>
                }

                {!isPaid &&
                    <p style={styles.notPaid}>( {t('order.no_paid')} )</p>
                }
            </div>

            {
                data &&
                data.coinName === 'coinz' &&
                !showCoinz &&

                <div style={styles.center}>
                    <button
                        onClick={handleToggleCoinz}
                        style={styles.coinzButton}
                    >
                        <MdOfflineBolt
                            size='1.5rem'
                            style={styles.icon}
                        />
                        {t('coinz.open_coinz')} PowerCoinz
                    </button>
                </div>
            }

            {
                data &&
                data.coinName === 'coinz' &&
                showCoinz &&

                <PowerCoinz
                    thankyou={true}
                    showCoinz={showCoinz}
                    setShowCoinz={setShowCoinz}
                />
            }

            {!isPaid &&
                <PaymentStatic
                    order={data}
                    isVerified={isVerified}
                    setIsVerified={setIsVerified}
                />
            }

            {!isPaid &&
                <div style={styles.space}></div>
            }

            {!isVerified && isPaid &&
                <EnterEmail
                    orderNumber={data.orderNumber}
                    setIsVerified={setIsVerified}
                    highlightInput={highlightInput}
                    setHighlightInput={setHighlightInput}
                />
            }

            {isVerified &&
                <div style={styles.buttonContainer}>
                    <button
                        onClick={logout}
                        style={styles.logoutButton}
                    >
                        {t('order.logout')}
                    </button>
                </div>
            }

            <TotalAndOrderNum data={data} />

            <OrderItemsList
                order={data}
                isDownloadable={isDownloadable}
                isPaid={isPaid}
                isVerified={isVerified}
                setHighlightInput={setHighlightInput}
            />

            {isVerified &&
                <OtherOrders
                    thisOrderNumber={data.orderNumber}
                />
            }
        </div>
    )
}

const styles = {
    error: {
        textAlign: 'center',
        textTransform: 'uppercase',
        marginTop: 200,
        marginBottom: 100,
    },
    thankYou: {
        color: 'lime'
    },
    buttonContainer: {
        width: '100%',
        textAlign: isMobile ? 'center' : 'left',
        marginTop: 20,
    },
    logoutButton: {
        marginBottom: 30,
    },
    space: {
        height: 50,
    },
    notPaid: {
        color: 'crimson',
        fontSize: '1.25rem',
        margin: 0,
        marginRight: isMobile ? 0 : 10,
    },
    paid: {
        margin: 0,
    },
    coinzButton: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingRight: 15,
    },
    center: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: 0,
    },
    icon: {
        transform: 'rotate(20deg)',
        marginRight: 8
    },
}

export default OrderDetail