import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const BurgerMenuItem = ({ item, num, listNum, closeMenu }) => {
    const { t } = useTranslation()

    const counterText = {
        color: 'black',
        fontSize: 12,
        transform: 'translateY(-13px)',
        marginLeft: num > 9 ? 2 : 5,
        position: 'fixed',
    }
    const counterFav = {
        color: 'black',
        fontSize: 12,
        transform: 'translateY(-13px)',
        marginLeft: listNum > 9 ? 2 : 5,
        position: 'fixed',
    }

    return (
        <NavLink
            to={item.url}
            style={({ isActive }) => (isActive ? styles.activeStyle : styles.menuItem)}
            onClick={closeMenu}
        >
            <div style={styles.icon}>{item.icon}</div>

            {item.label === 'cart' && num > 0 &&
                <div style={styles.counter}>
                    <p style={counterText}>{num}</p>
                </div>
            }

            {item.label === 'favorites' && listNum > 0 &&
                <div style={styles.counter}>
                    <p style={counterFav}>{listNum}</p>
                </div>
            }

            <div style={styles.cursor}> {item.label === 'powercoinz'
                ? 'PowerCoinz'
                : t(`nav.${item.label}`)}</div>
        </NavLink>
    )
}

const styles = {
    counter: {
        borderRadius: '7.5px',
        height: '17px',
        width: '17px',
        transform: 'translateY(-3px) translateX(5px)',
        paddingRight: 2,
        boxShadow: '2px 3px rgba(0,0,0,0.25)',
        backgroundColor: 'rgba(255,255,255,0.95)',
        position: 'fixed',
    },
    activeStyle: {
        fontSize: '1.3rem',
        margin: 5,
        color: 'gray',
        textDecorationLine: 'none',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        border: '1px solid gray',
        borderRadius: 5,
        padding: 5,
        transform: 'translateX(-6.25px)',
        cursor: 'default',
    },
    menuItem: {
        fontSize: '1.3rem',
        margin: 5,
        color: '#bdc3c7',
        textDecorationLine: 'none',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    icon: {
        marginRight: 20,
        transform: 'translateY(4px)'
    },
    cursor: {
        cursor: 'pointer'
    },
}

export default BurgerMenuItem