// import { isMobile } from 'react-device-detect'
// import { useTranslation } from 'react-i18next'

const TrackerNotification = () => {
    return null
    // const { t } = useTranslation()
    // return !isMobile && (
    //     <p style={styles.p}>{t('main.block_tracker')}</p>
    // )
}

// const styles = {
//     p: {
//         width: '88%',
//         marginLeft: 'auto',
//         marginRight: 'auto',
//         textAlign: 'center',
//         fontSize: '0.82rem'
//     },
// }

export default TrackerNotification