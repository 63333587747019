import { useState } from 'react'

const Faq = (props) => {
    const { question, answer } = props
    const [show, setShow] = useState(false)

    return <div>
        <div
            onClick={() => setShow(!show)}
            className='faqs_questionContainer'>
            <p className='faqs_question'>{question}</p>
        </div>
        <div className={show ? 'faqs_answer' : { display: 'none' }}>
            {show && answer}
        </div>
    </div>
}

export default Faq