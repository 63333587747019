import { useEffect, useState } from 'react'
import { BsHeartFill } from 'react-icons/bs'
import AddDvdBtn from '../buttons/AddDvdBtn'
import PurchasedBtn from '../buttons/PurchasedBtn'
import AddToCartBtnNoIcon from '../buttons/AddToCartBtnNoIcon'
import CardId from '../cards/CardId'
import { useTranslation } from 'react-i18next'
import { SectionLoader } from '../Loaders'
import { handleRemoveFromList } from '../toast'
import '../../styles/favorites.css'
import { findProduct } from '../../utils/sql/findProduct'
import { removeItem } from '../../utils/localStorage/functions'

const style = {
    transform: 'translateY(1px) translateX(1px)',
}

const filledHeart = <BsHeartFill
    size={14}
    style={style}
/>

const ListItem = (props) => {
    const { t } = useTranslation()
    const { item, action, setAction } = props

    const [product, setProduct] = useState({})
    const [visible, setVisible] = useState(true)

    useEffect(() => {
        const getTheProduct = async () => {
            const _product = await findProduct(item.id)
            setProduct(_product)
        }
        getTheProduct()
    }, [item])

    const deleteProduct = () => {
        removeItem('list', item.id)
        setVisible(false)
        setAction(!action)
        handleRemoveFromList(product.title)
    }

    const title = product && product.title
    const isDvd = product.pz_code ? true : false

    return !product
        ? <SectionLoader />
        : visible && (
            <div className='fav_shadowOnHover'>

                <CardId id={item.id} fav={true} />

                <div
                    key={item.id}
                    className='fav_summary'
                >
                    <PurchasedBtn item={item} />

                    <div className='fav_button'>
                        {isDvd
                            ? <AddDvdBtn id={product.pz_code} />
                            : <div className='fav_hide'></div>
                        }
                    </div>

                    <div className='fav_button'>
                        <AddToCartBtnNoIcon
                            id={product.id}
                            title={title}
                        />
                    </div>

                    <div className='fav_button'>
                        <button
                            title={t('main.remove_from_favs')}
                            onClick={deleteProduct}
                        >{t('main.remove_from_favs')} {filledHeart}</button>
                    </div>
                </div>
            </div>
        )
}

export default ListItem