import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer, toast } from 'react-toastify'
import i18n from '../i18n/i18n'
import { isMobile } from 'react-device-detect'

const time = 1500
const extendedTime = 2500

export const ToastComp = () => {
    return (
        <ToastContainer
            position="top-right"
            autoClose={time}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            closeButton={false}
        />
    )
}

export const handleToast = () =>
    toast.success(i18n.t('toast.copied'), {
        position: 'top-right',
        autoClose: time,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    })

export const handleAddToCart = (title) =>
    toast.success(
        i18n.language === 'en'
            ? `${title} added to cart`
            : i18n.t('toast.added_to_cart')
        , {
            position: 'top-right',
            autoClose: time,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        })

export const handleAddAllToCart = (title) =>
    toast.success(
        i18n.language === 'en'
            ? `Everything from ${title} added to cart`
            : i18n.t('toast.everything_added')
        , {
            position: 'top-right',
            autoClose: time,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        })

export const handleRemoveFromToCart = (title) =>
    toast.info(
        i18n.language === 'en'
            ? `${title} removed from cart`
            : i18n.t('toast.removed_from_cart')
        , {
            position: 'top-right',
            autoClose: time,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        })

export const handleAddToList = (title) =>
    toast.success(
        i18n.language === 'en'
            ? `${title} added to favorites`
            : i18n.t('toast.added_to_favs')
        , {
            position: 'top-right',
            autoClose: time,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        })

export const handleRemoveFromList = (title) =>
    toast.info(
        i18n.language === 'en'
            ? `${title} removed from favorites`
            : i18n.t('toast.removed_from_favs')
        , {
            position: 'top-right',
            autoClose: time,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        })

export const handleNoOrderFound = () =>
    toast.error(i18n.t('toast.order_not_found'), {
        position: 'top-right',
        autoClose: time,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    })

export const handleError = (message) =>
    toast.error(message, {
        position: 'top-right',
        autoClose: time,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    })

export const handleGenToast = (message) =>
    toast.success(message, {
        position: 'top-right',
        autoClose: time,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    })

export const handleGenInfo = (message) =>
    toast.info(message, {
        position: 'top-right',
        autoClose: time,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    })

export const handleThankYou = () =>
    toast(`🥰 ${i18n.t('toast.thank_you')}`, {
        position: "top-right",
        autoClose: extendedTime,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    })

const message = () => {
    const num = i18n.language === 'en' ? Math.floor(Math.random() * 6) : 1
    switch (num) {
        case 1:
            return `😍 ${i18n.t('toast.satisfaction')}`
        case 2:
            return '🔥 Hotness Guaranteed!'
        case 3:
            return '🦄 One of a kind!'
        case 4:
            return '🍭 Sweet Deal!'
        case 5:
            return "💋 Gorgeous Girls in Bondage!"
        default:
            return "🌜 You'll be over the moon!"
    }
}

export const handleGuarantee = () => {
    return toast(message, {
        position: "top-right",
        autoClose: extendedTime,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    })
}

export const handlePromise = (func) => {
    toast.promise(
        func,
        {
            pending: 'Promise is pending',
            success: 'Promise resolved 👌',
            error: 'Promise rejected 🤯'
        }
    )
}

export const handleBanned = (message) =>
    toast(({ closeToast }) => (
        <div style={styles.bannedDiv}>
            <h3 style={styles.bannedMessage}>{message}</h3>
            <button
                style={styles.bannedButton}
                onClick={closeToast}>
                {i18n.t('main.close')}
            </button>
        </div>
    ), {
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        position: "top-center",
        style: {
            backgroundColor: '#ddd',
            color: 'black',
            boxShadow: '1px 2px 8px rgba(0,0,0,0.8)',
            width: isMobile ? '100%' : '120%'
        }
    })

const styles = {
    bannedDiv: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        border: '5px solid crimson',
        borderRadius: 10,
        padding: 5,
    },
    bannedButton: {
        backgroundColor: 'crimson',
        transform: 'scale(.8)'
    },
    bannedMessage: {
        margin: 0,
        marginTop: 10,
    },
}
