import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import '../../styles/contact.css'
import { validNameRegex, validEmailRegex, validMessageRegex } from '../../utils/functions/validate'

const ContactForm = () => {
    const { t } = useTranslation()
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')
    const [success, setSuccess] = useState('')
    const [error, setError] = useState('')

    const clearForm = () => {
        setName('')
        setEmail('')
        setMessage('')
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setSuccess('')
        setError('')
        if (
            validEmailRegex.test(email) &&
            validMessageRegex.test(message) &&
            validNameRegex.test(name)
        ) {
            setSuccess(t('main.sending'))
            const data = { name, email, message }
            const url = 'https://data.powershotz.com/php/contact.php'

            // USING PHP
            fetch(url, {
                method: 'POST',
                body: JSON.stringify(data),
            })
                .then(() => setSuccess(t('main.success')))
                .then(() => clearForm())
                .catch(e => setError(e.message))
        } else {
            setError('invalid character')
        }
    }

    const handleChange = (e) => {
        setError('')
        setSuccess('')
        switch (e.target.name) {
            case 'name':
                setName(e.target.value)
                break
            case 'email':
                setEmail(e.target.value)
                break
            case 'message':
                setMessage(e.target.value)
                break
            default:
                break
        }
    }

    return <div className={`contact_mainContact`}>
        <form onSubmit={handleSubmit} className={`contact_formWrapper`}>
            <input
                onChange={handleChange}
                name='name'
                className={`contact_inputContact`}
                value={name}
                required
                placeholder={t('main.name')}
            />

            <input
                onChange={handleChange}
                name='email'
                type='email'
                className={`contact_inputContact`}
                value={email}
                required
                placeholder={t('main.email')}
            />

            <textarea
                onChange={handleChange}
                name='message'
                className={`contact_inputContact`}
                value={message}
                required
                placeholder={t('main.message')}
            />

            <div className={`contact_buttonContainer`}>
                <button className={`contact_button`}>{t('main.submit')}</button>
            </div>

            <p className={`contact_success`}>
                {success}
            </p>
            <p className={`contact_error`}>
                {error}
            </p>
        </form>
    </div>
}

export default ContactForm