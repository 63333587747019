import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import CartItemsList from '../comps/cart/CartItemsList'
import MetaDecorator from '../helmet/MetaDecorator'
import '../styles/cart.css'
import { findProduct } from '../utils/sql/findProduct'
import { formatPrice } from '../utils/functions/formatPrice'
import { getLocalStorage, removeAllItems } from '../utils/localStorage/functions'
import { handleError } from '../comps/toast'

const Cart = () => {
    const { t, i18n } = useTranslation()

    const [cart, setCart] = useState([])
    const [total, setTotal] = useState(0)
    const [num, setNum] = useState(0)
    const [action, setAction] = useState(true)
    const [message, setMessage] = useState(t('cart.empty'))


    useEffect(() => {
        const body = document.getElementById('root')
        body.scrollIntoView(true)
    }, [])

    const fetchCartData = () => setCart(getLocalStorage('cart'))

    useEffect(() => {
        if (action) {
            fetchCartData()
            setAction(false)
        }
    }, [action])


    useEffect(() => {
        setTotal(0)

        if (cart.length > 0 && !cart.some(obj => 'id' in obj)) {
            removeAllItems('cart')
            handleError(t('main.cart_error'))
            setMessage(t('main.cart_error'))
        }

        cart &&
            cart.map(async (item) => {
                let product = await findProduct(item.id)
                product && setTotal((prev) => prev + parseFloat(product.price))
            })
        setNum(cart.length)
        // eslint-disable-next-line
    }, [cart])

    return (
        <div className={`cart_main`}>

            <MetaDecorator
                title="Cart"
                description="Order downloadable Powershotz.com videos and photos featuring gorgeous girls in bondage."
            />

            <div className={`cart_heading`}>
                <h1>{t('nav.cart')}</h1>
                {num > 0 && (
                    <Link to='/checkout'>
                        <button>
                            {t('cart.checkout_now')}
                        </button>
                    </Link>
                )}
            </div>

            <p className={`cart_otherForm`}>

                <Link to={'/dvds'}>
                    {t('cart.dvd_link')}
                </Link>
            </p>

            {!cart || num === 0 ? (
                <div className={`cart_empty`}>
                    <h2>{message}</h2>
                </div>
            ) : (
                <>
                    <div className={`totalOrderNumber`}>
                        <h2>
                            <span className={`cart_fade`}>{t('cart.total')}</span>
                            ${formatPrice(total)} {!i18n.language.includes('en') && 'USD'}
                        </h2>
                    </div>

                    <CartItemsList
                        num={num}
                        total={total}
                        setTotal={setTotal}
                        action={action}
                        setAction={setAction}
                        modal={false}
                    />

                    {num > 0 && (
                        <div
                            style={styles.checkoutButtonBottom}
                        >
                            <Link to='/checkout'>
                                <button style={styles.bigButton}>
                                    {t('cart.checkout_now')}
                                </button>
                            </Link>
                        </div>
                    )}
                </>
            )}
        </div>
    )
}

const styles = {
    checkoutButtonBottom: {
        textAlign: 'center',
        width: '100%'
    },
    bigButton: {
        width: '65%',
        fontSize: '1.75rem'
    }
}

export default Cart
