import MetaDecorator from '../helmet/MetaDecorator'
import '../styles/about.css'

const USC = () => {
    window.scrollTo(0, 0)
    return (
        <div className='main'>
            <MetaDecorator title="USC" description="US Code: Title 18, Section 2257" />
            <h1>From US Code: Title 18, Section 2257</h1>
            <p className='justify'>Part of US Code as of: 01/23/00 Sec. 2257. Record keeping requirements (a) Whoever produces any book, magazine, periodical, film, videotape, or other matter which - (1) contains one or more visual depictions made after November 1, 1990 of actual sexually explicit conduct; and (2) is produced in whole or in part with materials which have been mailed or shipped in interstate or foreign commerce, or is shipped or transported or is intended for shipment or transportation in interstate or foreign commerce; shall create and maintain individually identifiable records pertaining to every performer portrayed in such a visual depiction. (b) Any person to whom subsection (a) applies shall, with respect to every performer portrayed in a visual depiction of actual sexually explicit conduct - (1) ascertain, by examination of an identification document containing such information, the performer&apos;s name and date of birth, and require the performer to provide such other indicia of his or her identity as may be prescribed by regulations; (2) ascertain any name, other than the performer&apos;s present and correct name, ever used by the performer including maiden name, alias, nickname, stage, or professional name; and (3) record in the records required by subsection (a) the information required by paragraphs (1) and (2) of this subsection and such other identifying information as may be prescribed by regulation. (c) Any person to whom subsection (a) applies shall maintain the records required by this section at his business premises, or at such other place as the Attorney General may by regulation prescribe and shall make such records available to the Attorney General for inspection at all reasonable times.</p>
        </div>
    )
}

export default USC