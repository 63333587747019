import { BsHeart } from 'react-icons/bs'
import { useTranslation } from 'react-i18next'
import '../../styles/favorites.css'

const NoList = () => {
    const { t } = useTranslation()

    return (
        <div className='empty'>
            <h2>{t('favs.no_items')} 😧</h2>
            <p className='emptyText'>{t('favs.add_stuff')}
                <span>
                    <BsHeart
                        color='crimson'
                        size={20}
                        className='emptyIcon'
                    />
                </span>
            </p>
        </div>
    )
}

export default NoList