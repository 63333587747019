import { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { HiOutlineXCircle } from "react-icons/hi2"
import { useScrollRestoration } from 'use-scroll-restoration'
import Card from '../../comps/cards/Card'
import SortComponent from '../../comps/SortComponent'
import '../../styles/forms.css'
import { sortProducts } from '../../utils/functions/sortProducts'

const SearchComp = () => {
    const { t, i18n } = useTranslation()
    const { ref } = useScrollRestoration("search", {
        persist: "sessionStorage",
    })

    const inputRef = useRef(null)

    const [products, setProducts] = useState([])
    const [searchPhrase, setSearchPhrase] = useState('')
    const [sortType, setSortType] = useState('default')
    const [init, setInit] = useState(false)

    const search = async () => {
        const list = await sortProducts(sortType, searchPhrase)
        list && list.length > 0
            ? setProducts(list)
            : setProducts([])
    }

    useEffect(() => {
        const phrase = sessionStorage.getItem('searchPhrase')
        phrase && phrase.length > 0 && setSearchPhrase(phrase)

        const _sortType = sessionStorage.getItem('sortVideos')
        _sortType && _sortType.length > 0 && setSortType(_sortType)

        setInit(true)
    }, [])

    useEffect(() => {
        search()
        // eslint-disable-next-line
    }, [init, sortType])

    const clearForm = () => {
        setSearchPhrase('')
        inputRef.current.focus()
        setProducts([])
    }

    const handleChange = (e) => {
        setSearchPhrase(e.target.value)
        sessionStorage.setItem('searchPhrase', e.target.value)
    }

    const handleSort = (e) => {
        setSortType(e.target.value)
        sessionStorage.setItem('sortVideos', e.target.value)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        sessionStorage.setItem('sortVideos', 'default')
        search()
    }

    return (
        <div>
            <h1>{t('nav.search')}</h1>
            {i18n.language !== 'en' && <p style={{ marginTop: -20 }}>({t('main.in_en')})</p>}

            <form onSubmit={handleSubmit} style={styles.form}>
                <div className='searchInputContainer'>
                    <input
                        value={searchPhrase}
                        onChange={handleChange}
                        type="text"
                        className='searchInput'
                        autoFocus
                        ref={inputRef}
                    />

                    <HiOutlineXCircle
                        onClick={clearForm}
                        className='clearFormIcon'
                        title={t('main.clear')}
                    />

                    <div
                        onClick={handleSubmit}
                        className='submitSearchButton'
                        title={t('main.submit')}
                    >{t('main.submit')}</div>
                </div>
            </form>

            <SortComponent
                sortType={sortType ? sortType : 'default'}
                handleSort={handleSort}
                className='sort'
            />

            <h3 className='searchMessage'>
                {`${products.length} ${t('main.found')}`}
            </h3>

            <ul ref={ref} style={styles.ul}>
                {products &&
                    products.map(product => {
                        return <Card
                            key={product.id}
                            product={product}
                            searchPhrase={searchPhrase}
                        />
                    })
                }
            </ul>
        </div>
    )
}

const styles = {
    ul: {
        listStyle: 'none',
        paddingLeft: 0,
        marginTop: -10,
    },
    form: {
        margin: 'auto',
        maxWidth: 750,
    },
}

export default SearchComp