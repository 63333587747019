import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BsHeart, BsHeartFill } from 'react-icons/bs'
import { handleAddToList, handleRemoveFromList } from '../toast'
import '../../styles/products.css'
import { addItem, removeItem, isInList } from '../../utils/localStorage/functions'

const SmallAddToListBtn = (props) => {
    const { t } = useTranslation()
    const { id, title } = props

    const [inList, setInList] = useState(false)

    useEffect(() => {
        setInList(isInList(id))
    }, [id])

    const handleClick = () => {
        setInList(inList => !inList)
        if (inList) {
            handleRemoveFromList(title)
            removeItem('list', id, setInList)
        } else {
            handleAddToList(title)
            addItem('list', id, setInList)
        }
    }

    const icon = inList
        ? <BsHeartFill
            className='prod_heartIcon'
            size={20}
            title={t('main.remove_from_favs')}
            onClick={handleClick}
        />
        : <BsHeart
            className='prod_heartIcon'
            size={20}
            title={t('main.add_to_favs')}
            onClick={handleClick}
        />

    return <span>{icon}</span>
}

export default SmallAddToListBtn