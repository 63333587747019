import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { handleAddAllToCart } from '../toast'
import { addItem, removeItem } from '../../utils/localStorage/functions'

const BuyAll = (props) => {
    const { t, i18n } = useTranslation()
    const {
        product,
        starsIn,
        action,
        setAction
    } = props

    const [titleText, setTitleText] = useState(t('cart.add_all'))
    const [buttonText, setButtonText] = useState(t('cart.add_everything'))

    useEffect(() => {
        if (i18n.language === 'en') {
            setTitleText(`Add all of ${product.title}'s videos & photos to your cart!`)
        } else {
            setTitleText(t('cart.add_all_videos'))
        }

    }, [product, i18n.language, t])

    const addAllToCart = () => {
        // get all the model's videos & photos
        let lcName = product.title.toLowerCase().replace(/ /g, '')

        let videoIds = starsIn.filter(
            (item) => item.model_name.toLowerCase().replace(/ /g, '') === lcName
        )

        // add model to cart
        removeItem('cart', product.id)
        addItem('cart', product.id)

        // add videos to cart
        videoIds.forEach((item) => {
            removeItem('cart', item.vid_id)
            addItem('cart', item.vid_id)
        })

        handleAddAllToCart(product.title)
        setButtonText('added everything')
        setAction(!action)
    }

    return (
        <div>
            <button
                title={titleText}
                onClick={addAllToCart}>
                {buttonText}
            </button>
        </div>
    )
}

export default BuyAll
