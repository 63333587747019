import React from 'react'
import { Helmet } from 'react-helmet'
import { structuredOrganization, structuredProduct } from './jsonLd'

// ref: https://opengraphprotocol.org/
// ref: https://css-tricks.com/essential-meta-tags-social-media/
// test: https://metatags.io/
// test: https://metapreview.app/
// test: https://www.linkedin.com/post-inspector/inspect/

// title, description, keywords, image, url, price, type (product||organization)

const MetaDecorator = (props) => {
  const title = props.title
    ? `Powershotz | ${props.title}`
    : 'Powershotz | Gorgeous Girls in Bondage!'

  const description = props.description
    ? props.description
    : 'Gorgeous Girls in Bondage! All original BDSM, Damsel-in-distress, Chloroform & Abduction Fantasy Videos and Photos. Powershotz contains materials of an adult nature, and is intended only for individuals 18 years of age and older.'

  const noscript = props.noscript
    ? props.noscript
    : 'Welcome to Powershotz.com! You need to enable JavaScript to visit this site.'

  const keywords = props.keywords
    ? props.keywords
    : 'powershotz, powershotz.com, powershopz, hazel powershotz, hazel from powershotz, power shotz, damsel,damsel in distress,damselindistress,did,strugglefuck,cf,fantasy,rape play,rapeplay,teen,18yo,adult,xxx,hardcore,blonde,blondes,struggle,struggling,kidnap,escape,escaping,capture,zipties,zip tie,ducttape,duct tape,hazel,heathersilk,heather silk,tiki,titties,big tits,tits,struggle fuck, fetish, collared, bdsm, slave, slavery, servant, foot, feet, toes, kink, kinky, leather, rubber, latex, worship, fuck, forced blowjob, forced sex, predicament bondage, bondage, spank, spanked, spanking, whip, whipped, whipping, flog, flogged, flogging, hogtie, cuffed, master slave, master, nosehook, gag, gagged, gagging, abduction, anal, mindfuck, mind fuck, brat, brutal, cock worship, tease, denial, spreadeagle, suspension bondage, bitch, no escape, strict, corporal punishment, cp, suspension, abused, dungeon, punishment, caning, pain, suffer, corset, nylons, socks, sub, submissive, discipline, worship, blowjob, chloro, chloroform, forced fucking, rope, shibari, steel cuffs, leather cuffs, crotch rope, strapped, bitch strip, hair pulling, breath play, choke, hoods, masks,hair,hair pulling,hair brushing, massage,dildo,glass dildo,vibrator,forced orgasm,blowjob,blow job,choking,ballgag,submission,feet,dirty feet,force,forced orgasm, domination, slave training, anal training, international, chinese, 普通话, cn, Deutsch, de, german, Español, es, spanish, Français, fr, french, italiano, it, italian, 日本語, ja, japanese, Русский, ru, russian '

  const image = props.image ? props.image : 'https://data.powershotz.com/cindy-1.jpg'

  const url = props.url ? props.url : window.location.href

  const siteName = props.siteName ? props.siteName : 'Powershotz'

  const price = props.price ? props.price : '9.99'

  const type = props.type ? props.type : 'organization'

  const structuredData = type === 'product'
    ? structuredProduct(title, image, description, url, price)
    : structuredOrganization

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />

      <link
        rel="icon"
        type="image/x-icon"
        href="https://classic.powershotz.com/favicon.ico"
      />
      <noscript>{noscript}</noscript>
      <meta name="keywords" content={keywords} />
      <meta name="author" content="Powershotz.com" />

      <meta name="google-site-verification" content="KqF7DZZ5mGFX0RVtpzoly_ip5gVVQkRdWE9h960lRfo" />

      {/* <!--  Essential META Tags --> */}
      <meta property="og:title" content={title} />
      <meta property="og:type" content="website" />
      <meta property="og:image" content={image} />
      <meta property="og:url" content={url} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@powershopz" />

      {/* <!--  Non-Essential, But Recommended --> */}
      <meta property="og:description" content={description} />
      <meta property="og:site_name" content={siteName} />
      <meta name="twitter:image:alt" content={`Powershotz | ${title}`} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />

      <script
        type="application/ld+json">{structuredData}</script>


    </Helmet>
  )
}

export default MetaDecorator
