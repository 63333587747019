import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import FasterProcessing from '../cart/FasterProcessing'
import Modal from '../Modal'
import PaymentHelpContent from './PaymentHelpContent'

const PaymentHelp = () => {
    const { t } = useTranslation()
    const [showModal, setShowModal] = useState(false)
    const [showModal2, setShowModal2] = useState(false)

    return (
        <div style={styles.main}>
            {/* <a
                href='https://powershopz.com/crypto_unleashed.pdf'
                target="_blank"
                rel="noopener noreferrer"
            >
                <button
                    className='infoButton'
                    style={styles.button}
                >{t('payment.buy_crypto_link')}
                </button>
            </a> */}

            <button
                className="infoButton"
                style={styles.button}
                onClick={() => setShowModal(true)}
            >{t('help.help')}
            </button>

            <button
                className='infoButton'
                style={styles.button}
                onClick={() => setShowModal2(true)}
            >
                {t('payment.faster')}
            </button>

            <Modal
                onClose={() => setShowModal2(false)}
                show={showModal2}
                title={t('payment.faster')}
                buttonText=""
                link=""
            >
                <FasterProcessing setShowModal={setShowModal} />
            </Modal>

            <Modal
                onClose={() => setShowModal(false)}
                show={showModal}
                title=""
                buttonText=""
                link=""
            >
                <PaymentHelpContent setShowModal={setShowModal} />
            </Modal>
        </div>
    )
}

const styles = {
    main: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 10,
        transform: 'scale(0.9)'
    },
    button: {
        // minWidth: isMobile ? '100%' : 500,
        // maxWidth: isMobile ? '100%' : 500,
        width: 250,
        margin: 5,
    }
}

export default PaymentHelp