import { sortProducts } from '../../../utils/functions/sortProducts'
import { getProductId } from '../../../utils/functions/getProductId'
import { getAllPhotos } from '../../../utils/sql/getAllPhotos'
import { getAllVideos } from '../../../utils/sql/getAllVideos'

export const getNextPrev = async (product, setPrev, setNext, setPrevTitle, setNextTitle) => {
    if (!product) return

    let products
    let type = 'clip'
    if (product.type === 'clip') {
        products = await getAllVideos()
    } else {
        products = await getAllPhotos()
        type = 'photos'
    }

    const sorted = await sortProducts('nameAsc', type)

    let index = sorted.findIndex((item, i) => {
        let val = null
        if (item.id === product.id) val = i
        return val
    })

    const nextProduct = index >= products.length - 1
        ? sorted[0]
        : index === -1
            ? sorted[1]
            : sorted[index + 1]
    const prevProduct = index < 1
        ? sorted[products.length - 1]
        : sorted[index - 1]

    const prev = getProductId(prevProduct)
    const next = getProductId(nextProduct)
    const prevTitle = prevProduct && prevProduct.title
    const nextTitle = nextProduct && nextProduct.title

    setPrev(prev)
    setNext(next)
    setPrevTitle(prevTitle)
    setNextTitle(nextTitle)
}
