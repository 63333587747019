// import { formatLength } from './formatLength'
import { inPlaceSort } from 'fast-sort'
import { searchProducts } from '../../comps/search/searchProducts'

export const sortProducts = async (method, searchPhrase) => {
    // TODO: kristalee
    if (searchPhrase.toLowerCase() === 'kristalee') return null

    const list = await searchProducts(searchPhrase)
    // console.time()

    switch (method) {
        case 'priceAsc':
            return inPlaceSort(list).asc(u => parseFloat(u.price))
        case 'priceDesc':
            return inPlaceSort(list).desc(u => parseFloat(u.price))
        case 'lengthAsc':
            return inPlaceSort(list).asc(u => parseFloat(u.length))
        case 'lengthDesc':
            return inPlaceSort(list).desc(u => parseFloat(u.length))
        case 'nameAsc':
            return inPlaceSort(list).asc(u => u.title)
        case 'nameDesc':
            return inPlaceSort(list).desc(u => u.title)
        default:
            return list
    }
    // console.timeEnd()

}