export const searchProducts = async (searchTerm) => {
    if (!searchTerm) return []
    const products = []
    const term = searchTerm.replace(/ /g, '%20')
    const url = `https://data.powershotz.com/php/searchProducts.php?searchTerm=${term}`

    await fetch(url)
        .then(res => res.json())
        .then((data) => data &&
            data.map(i => products.push(i)))
        .catch((e) => console.error(`Error: ${e.message}`))

    return products
}