import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import SmallAddToListBtn from '../buttons/SmAddToListBtn'
import '../../styles/cart.css'
import { formatPrice } from '../../utils/functions/formatPrice'
import { findProduct } from '../../utils/sql/findProduct'
import { removeItem } from '../../utils/localStorage/functions'
import { handleRemoveFromToCart } from '../toast'
import { useTranslation } from 'react-i18next'

const CartItem = (props) => {
    const { t } = useTranslation()
    const {
        id,
        modal,
        setAction = () => { },
        setTotal = () => { },
    } = props

    const [product, setProduct] = useState({})
    const [visible, setVisible] = useState(true)

    useEffect(() => {
        const getTheProduct = async () => {
            const _product = await findProduct(id)
            setProduct(_product)
            _product && setTotal(prev => prev + parseFloat(_product.price))
        }
        getTheProduct()

        // setTotal causes loop
        // eslint-disable-next-line
    }, [id])

    const deleteProduct = () => {
        removeItem('cart', id)
        setVisible(false)
        handleRemoveFromToCart(product.title)
        setAction(true)
    }

    if (!product) return <div>{t('main.loading')}</div>

    const productId = product && product.pz_code
        ? product.pz_code
        : product.id
    const price = product && formatPrice(product.price)
    const title = product && product.title
    const type = product && product.type === 'photos'
        ? t('nav.photos')
        : t('main.video')
    const pathId = type === 'video'
        ? product.pz_code
            ? product.pz_code
            : product.id
        : title

    return modal
        ? (
            <div key={id} className='modalItem'>
                <div>{title}</div>
                <div>${price}</div>
            </div>
        )
        : visible && (
            <div
                key={id}
                className='cart_summary'
            >
                <Link
                    className='cart_productName'
                    to={`/${pathId}`}
                >{title ? title : t('main.loading')}
                </Link>

                <SmallAddToListBtn
                    id={product.id}
                    title={product.title}
                />

                <div className='priceTypeId'>({type})</div>
                <div className='priceTypeId'>#{productId}</div>
                <div className='priceTypeId'>${price}</div>
                <button
                    onClick={deleteProduct}>{t('main.delete')}</button>
            </div>
        )
}

export default CartItem