import PaymentCrypto from './PaymentCrypto'
import '../../styles/paymentCard.css'

const PaymentCardCrypto = (props) => {
    const {
        coinPrice,
        setCoinPrice,
        coin,
        setCoin,
        coinList,
        total,
        txId,
        setTxId,
        dvd,
        setError,
    } = props


    return (
        <div className={`cardMain`} style={styles.main}>
            <div className={`pc_title`} onClick={() => setCoin('none')}>
                <div className={`coinName`}>
                    <img src="https://data.powershotz.com/crypto.png" className={`pc_logo`} alt='crypto' />
                    <h4>Cryptocurrency</h4>
                </div>
            </div>

            <div className={`pc_info`}>
                <PaymentCrypto
                    coinPrice={coinPrice}
                    setCoinPrice={setCoinPrice}
                    coin={coin}
                    setCoin={setCoin}
                    coinList={coinList}
                    total={total}
                    txId={txId}
                    setTxId={setTxId}
                    dvd={dvd}
                    setError={setError}
                />
            </div>
        </div >
    )
}

const styles = {
    main: {
        backgroundColor: 'white',
    }
}

export default PaymentCardCrypto