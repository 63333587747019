import { useTranslation } from 'react-i18next'
import OrderItem from './OrderItem'
import '../../styles/cart.css'

const OrderItemsList = (props) => {
    const { t, i18n } = useTranslation()
    const {
        order,
        isDownloadable,
        isPaid,
        isVerified,
        setHighlightInput
    } = props

    if (!order) return null

    const items = order.items.split(", ")
    const s = items && items.length === 1 ? '' : 's'

    return (
        <div className={`cartItemsContainer`} style={styles.main}>

            <div style={styles.flexIt}>

                {i18n.language === 'en'
                    ? <h3>{items.length} item{s}</h3>
                    : <h3>{items.length} {t('order.item')}</h3>
                }

                {isPaid
                    ? null
                    : <button
                        onClick={() => window.scrollTo(0, 0)}
                    >{t('order.pay_now')}</button>
                }
            </div>

            {items && items.map((i) => {
                return <OrderItem
                    key={i}
                    item={i.trim()}
                    isDownloadable={isDownloadable}
                    isPaid={isPaid}
                    isVerified={isVerified}
                    orderType={order.order_type}
                    setHighlightInput={setHighlightInput}
                />
            })
            }
        </div >
    )
}

const styles = {
    main: {
        paddingTop: 10,
    },
    flexIt: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    }
}

export default OrderItemsList