import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { isMobile } from 'react-device-detect'
import TransactionId from '../payments/TransactionId'
import { fetchCoinPrice } from '../payments/fetchCoinPrice'
import CopyComp from '../CopyComp'
import CreditCardFrame from './creditCardFrame'
import '../../styles/cart.css'

const PaymentStatic = (props) => {
    const { t } = useTranslation()
    const { order, isVerified, setIsVerified } = props

    const [coinPrice, setCoinPrice] = useState('')
    const [addy, setAddy] = useState(order ? order.my_wallet : '')

    useEffect(() => {
        setAddy(order.my_wallet)
    }, [order])

    useEffect(() => {
        fetchCoinPrice(order.coinName, setCoinPrice, order.total)
    }, [order])

    let abbr = order.coinAbbr
    let src = order.coinAbbr.toLowerCase()

    if (order.coinAbbr === 'USD') {
        abbr = ''
        src = ''
    } else if (order.coinAbbr === 'ZELLE') {
        abbr = 'Zelle'
        src = 'https://powershopz.com/altcoin/zelle_shotz.png'
    } else if (order.coinAbbr === 'CASHAPP') {
        abbr = 'Cash App'
        src = 'cashApp'
    } else if (order.coinAbbr === 'skrill') {
        abbr = 'USD'
    }

    if (order.coinName === 'coinz') return null

    return (
        <div className={`paymentContainer`}>
            <div id="pay"></div>

            <div
                className={`payment_static`}
                style={{
                    // background: 'rgba(255, 255, 255, 0.1)',
                    padding: isMobile ? '10px 20px' : '10px 30px',
                }} >

                <h2>{t('order.already_paid')}</h2>

                <p style={styles.margin0}>{t('order.be_patient')}</p>

                <p style={styles.bePatient}>{t('main.auto_update')}</p>

                {(order.coinName !== 'gumroad' || !isVerified) &&
                    <h2 style={styles.notMade}>{t('order.no_payment_yet')}</h2>
                }

                {order.coinName === 'gumroad'
                    ? <CreditCardFrame
                        order={order}
                        isVerified={isVerified}
                        setIsVerified={setIsVerified}
                    />
                    : <div style={styles.copyCompsAndImage}>
                        <div style={styles.copyComps}>
                            <div className={`copyCompStatic`}>
                                <p
                                    className={`cart_fade`}
                                    style={styles.send}                            >
                                    {t('payment.send')}
                                </p>

                                <CopyComp
                                    dark={true}
                                    text={`${coinPrice} ${abbr}`}
                                    textToCopy={coinPrice}
                                />
                            </div>

                            <div className={`copyCompStatic`}>
                                <p
                                    className={`cart_fade`}
                                    style={styles.send}
                                >
                                    {t('payment.to')}
                                </p>

                                <CopyComp
                                    dark={true}
                                    text={order.my_wallet === 'alexandra@powershopz.com'
                                        ? 'alexandra@powershotz.com '
                                        : addy}
                                    textToCopy={order.my_wallet === 'alexandra@powershopz.com'
                                        ? 'alexandra@powershotz.com '
                                        : addy}
                                />
                            </div>
                        </div>

                        {src &&
                            <div style={styles.image}>
                                <img
                                    src={order.coinAbbr === 'ZELLE'
                                        ? src
                                        : `https://powershopz.com/altcoin/${src}.png`}
                                    width={90}
                                    height={90}
                                    alt={abbr}
                                />
                            </div>
                        }
                    </div>
                }

                <TransactionId
                    data={order}
                    dark={true}
                />
            </div>
        </div >
    )
}

const styles = {
    send: {
        color: 'crimson',
    },
    bePatient: {
        marginTop: 5,
        borderBottom: '1px solid gray',
        paddingBottom: 20,
    },
    image: {
        minHeight: 20,
        marginRight: 5,
        marginLeft: 5,
    },
    notMade: {
        marginBottom: 15,
    },
    copyComps: {
        display: 'flex',
        flexDirection: 'column',
    },
    copyCompsAndImage: {
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 20,
    },
    refresh: {
        fontSize: 35,
        marginRight: 8,
        cursor: 'pointer',
        border: '1px solid red',
        borderRadius: 5,
        color: 'red'
    },
    note: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    margin0: {
        margin: 0,
    }

}

export default PaymentStatic