export const fetchCoinCap = async (coinName, total) => {
    const name = coinName.toLowerCase()
    const url = `https://api.coincap.io/v2/assets`

    const res = await fetch(url)
    const response = await res.json()

    let currentPrice = false

    response.data && response.data.map(i => {
        if (i.id === name) {
            currentPrice = i.priceUsd
        }
        return null
    })

    const coinPrices = currentPrice
        ? total / currentPrice
        : false

    return coinPrices
        ? coinPrices.toFixed(10)
        : false
}