import { Link } from 'react-router-dom'
import '../../styles/card.css'
import { formatPrice } from '../../utils/functions/formatPrice'
import { formatLength } from '../../utils/functions/formatLength'
import { useTranslation } from 'react-i18next'

const CardFav = ({ product }) => {
    const { t } = useTranslation()

    if (!product) return null

    let length, src, link, type

    if (product.type === 'clip') {
        length = `${formatLength(product.length)} ${t('main.minutes')}`
        src = `https://data.powershotz.com/c4sImages/${product.image}`
        link = `/${product.pz_code ? product.pz_code : product.id}`
        type = `(${t('main.video')})`
    } else {
        length = `${product.length} photos`
        const num = Math.floor(Math.random() * 4) + 1
        src = `https://data.powershotz.com/models2/${product.title.replace(/ /g, '').toLowerCase()}-${num}.jpg`
        link = `/${product.title}`
        type = `(${t('main.photos')})`
    }

    const price = formatPrice(parseFloat(product.price))
    const id = product.pz_code ? product.pz_code : product.id
    const title = product.title
    const description = product.description

    return (
        <div id="card">
            <div className='cardFav'>
                <Link to={link} className='imgContainerFav'>
                    <img
                        className='cardImageFav'
                        src={src}
                        alt={title}
                        width={300}
                        height={300}
                    />
                </Link>

                <div className='productInfoFav'>
                    <Link
                        to={link}
                        className='card_link'
                    >
                        <h3 className='card_fav_title'>{title}</h3>

                        <span className='card_fav_type'>{type}</span>

                        <button className='card_fav_button'>{t('main.more')} 🡢</button>
                    </Link>

                    <p className='paragraphFav'>{description}</p>

                    <div className='cardInfoFav'>
                        <p>{id}</p>
                        <p>${price}</p>
                        <p>{length}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CardFav