import PowerCoinz from '../comps/payments/PowerCoinz'

const PowerCoinzScreen = () => {
    return (
        <div style={styles.main}>
            <PowerCoinz screen={true} />
        </div>
    )
}

const styles = {
    main: {
        textAlign: 'center',
        maxWidth: 600,
        margin: 'auto',
    }
}

export default PowerCoinzScreen