import { Link } from 'react-router-dom'
import { FaXTwitter } from "react-icons/fa6"
import { onionAddress } from '../../constants/constants'
import { useTranslation } from 'react-i18next'
import tor from '../../images/tor.png'
import guarantee from '../../images/guarantee.png'
import '../../styles/footer.css'
import { useEffect, useState } from 'react'

const year = new Date().getFullYear()
const startYear = '2000'

const Footer = () => {
    const { t } = useTranslation()
    const [seed, setSeed] = useState(1)

    useEffect(() => {
        const handleLanguage = () => setSeed(Math.random())
        window.addEventListener('language', handleLanguage)
    }, [])

    return (
        <div className='footer_main' key={seed}>
            <div className='footer_grid'>
                <div className='footer_questions'>
                    <h4>{t('footer.how_do_i_order')}</h4>
                    <small>{t('footer.how_instructions')}</small>

                    <h4>{t('footer.how_quickly')}</h4>
                    <small>{t('footer.how_quickly_text')}</small>

                    <h4>{t('footer.why_dont_i')}</h4>
                    <small>
                        {t('footer.why_dont_i_text')}
                        &nbsp;&nbsp;
                        <Link to={"/contact"} className='footer_link'>{t('nav.contact_us')}.</Link>
                    </small>
                </div>

                <div className='footer_questions'>
                    <h4>{t('footer.delivery')}</h4>
                    <small>{t('footer.delivery_text')}</small>

                    <h4>{t('footer.guarantee')}</h4>
                    <small>{t('footer.guarantee_text')}</small>

                    <img
                        src={guarantee}
                        width={75}
                        height={75}
                        alt={t('footer.guarantee')}
                        className='footer_guaranteeImage'
                    />
                </div>
            </div>

            <footer>
                <div className='footer_footerContent'>
                    <h3>{t('nav.sitemap')}</h3>
                    <div className='footer_sitemap'>
                        <div className='footer_sitemapCol'>
                            <Link
                                to={"/"}
                                title={t('nav.home')}
                            >{t('nav.home')}</Link>

                            <Link
                                to={"/search"}
                                title={t('nav.search')}
                            >{t('nav.search')}</Link>

                            <Link
                                to={"/about"}
                                title={t('nav.about')}
                            >{t('nav.about')}</Link>

                            <Link
                                title={t('nav.faqs')}
                                to={"/faqs"}
                            >FAQs</Link>

                        </div>
                        <div className='footer_sitemapCol'>
                            <Link
                                to={"/contact"}
                                title={t('nav.contact')}
                            >{t('nav.contact')}</Link>

                            <Link
                                to={"/cart"}
                                title={t('nav.cart')}
                            >{t('nav.cart')}</Link>

                            <Link
                                to={"/favorites"}
                                title={t('nav.favorites')}
                            >{t('nav.favorites')}</Link>

                            <Link
                                to={"/order"}
                                title={t('nav.orders')}
                            >{t('nav.orders')}</Link>
                        </div>

                        <div className='footer_sitemapCol'>
                            <Link
                                to={"/videos"}
                                title={t('nav.videos')}
                            >{t('nav.videos')}</Link>

                            <Link
                                to={"/photos"}
                                title={t('nav.photos')}
                            >{t('nav.models_photos')}</Link>

                            <Link
                                to={"/dvds"}
                                title={t('nav.dvds')}
                            >{t('nav.dvds')}</Link>
                            <Link
                                to={"/language"}
                                title={t('nav.change_language')}
                            >{t('nav.change_language')}</Link>

                            {/* <Link to={classicPowershotz}>Classic View</Link> */}
                        </div>
                    </div>

                    <h3>{t('disclaimer.important')}</h3>

                    <p className='footer_important'>{t('disclaimer.text')}</p>

                    <div>
                        <a href="https://twitter.com/powershopz" title="Twitter">
                            <FaXTwitter size={40} style={{ marginRight: 15, color: 'snow' }} />
                        </a>

                        <a
                            title="For Tor Browsers Only"
                            href={onionAddress}
                        >
                            <img
                                src={tor}
                                alt="onion site"
                                width={100 / 3.5}
                                height={152 / 3.5}
                                className='footer_tor'
                            />
                        </a>
                    </div>

                    <p>
                        &copy; {startYear}-{year}{' '}
                        Powershotz
                        {' '}/{' '}
                        <a
                            alt="DesignTech"
                            href="http://akaDesignTech.com"
                            target="_blank"
                            rel="noopener noreferrer"
                        ><small>DesignTech</small>
                        </a>
                        {/* <br />
                        Made with ❤️ by Alexandra */}
                    </p>
                </div>
            </footer>
        </div>
    )
}

export default Footer