import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BsHeart, BsHeartFill } from 'react-icons/bs'
import { handleAddToList, handleRemoveFromList } from '../toast'
import { addItem, removeItem, isInList } from '../../utils/localStorage/functions'

const AddToListBtn = (props) => {
    const { t } = useTranslation()
    const { id, title, action } = props
    const [inList, setInList] = useState(false)

    useEffect(() => {
        setInList(isInList(id))
    }, [id, action])

    const handleClick = () => {
        if (isInList(id)) {
            handleRemoveFromList(title)
            removeItem('list', id, setInList)
        } else {
            handleAddToList(title)
            addItem('list', id, setInList)
        }
    }

    const icon = inList
        ? <BsHeartFill
            className={`prod_heartIcon`}
            size={30}
            title={t('main.remove_from_favs')}
            onClick={handleClick}
        />
        : <BsHeart
            className={`prod_heartIcon`}
            size={30}
            title={t('main.add_to_favs')}
            onClick={handleClick}
        />

    return (
        <div>{icon}</div>
    )

}

export default AddToListBtn