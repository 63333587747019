export const getModelData = async (id) => {
    const url = `https://data.powershotz.com/php/getModelData.php?id=${id}`

    try {
        const res = await fetch(url)
        const data = await res.json()
        return data
    } catch (e) {
        console.log(e)
        return false
    }
}