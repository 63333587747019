export const formatLength = (length) => {
    if (length.includes(':')) {
        const [h, m] = length.split(":").map(val => parseInt(val))
        const hours = parseInt(h) * 60
        const minutes = parseInt(m) + hours
        return minutes
    } else {
        return parseInt(length)
    }
}
