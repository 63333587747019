import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const IconMenuItem = ({ item, num }) => {
    const { t } = useTranslation()

    if (
        !item ||
        item.label === 'home' ||
        item.label === 'contact' ||
        item.label === 'dvds' ||
        item.label === 'about' ||
        item.label === 'faqs' ||
        item.label === 'orders' ||
        item.label === 'favorites' ||
        item.label === 'powercoinz'
    ) return null

    const counterText = {
        color: 'black',
        fontSize: 12,
        transform: 'translateY(-13px)',
        marginLeft: num > 9 ? 2 : 5,
        position: 'fixed',
    }

    return (
        <Link
            to={item.url}
            style={styles.link}
            title={t(`nav.${item.label}`)}
        >
            {item.label === 'cart' && num > 0 &&
                <div style={styles.counter}>
                    <p style={counterText}>{num}</p>
                </div>
            }
            {item.icon}
            <p style={styles.text}>{t(`nav.${item.label}`)}</p>
        </Link>
    )
}

const styles = {
    link: {
        textDecorationLine: 'none',
        color: 'snow',
        fontSize: 23,
        textAlign: 'center',
        width: 50,
        position: 'relative',
    },
    text: {
        fontSize: 10,
        margin: 0,
        marginTop: -5,
        textAlign: 'center'
    },
    counter: {
        borderRadius: '7.5px',
        height: '16px',
        width: '16px',
        transform: 'translateY(-3px) translateX(17px)',
        paddingRight: 2,
        boxShadow: '2px 3px rgba(0,0,0,0.25)',
        backgroundColor: 'rgba(255,255,255,0.95)',
        position: 'fixed',
    },
}

export default IconMenuItem