import PaymentPicker from '../payments/PaymentPicker'
import EmailAddressComment from './EmailAddressComment'

const DvdForm = (props) => {
    const {
        email,
        setEmail,
        name,
        setName,
        street,
        setStreet,
        city,
        setCity,
        state,
        setState,
        zip,
        setZip,
        comment,
        setComment,
        setError,
        coin,
        setCoin,
        coins,
        txId,
        setTxId,
        coinPrice,
        setCoinPrice,
        total,
        // order_type,
        saveOrder,
        orderNumber,
        error,
    } = props

    return (
        <div>
            <EmailAddressComment
                email={email}
                setEmail={setEmail}
                name={name}
                setName={setName}
                street={street}
                setStreet={setStreet}
                city={city}
                setCity={setCity}
                state={state}
                setState={setState}
                zip={zip}
                setZip={setZip}
                comment={comment}
                setComment={setComment}
                setError={setError}
                error={error}
            />

            <PaymentPicker
                coins={coins}
                coin={coin}
                setCoin={setCoin}
                coinPrice={coinPrice}
                total={total}
                txId={txId}
                setTxId={setTxId}
                setCoinPrice={setCoinPrice}
                orderId={orderNumber}
                email={email}
                saveOrder={saveOrder}
                setError={setError}
                error={error}
                dvd={true}
            />
        </div>
    )
}

export default DvdForm