export const getMedia = (product, link, gifData, modelData) => {
    if (!product) return []

    const id = product.pz_code
        ? product.pz_code
        : product.title

    const array = []
    let list = []
    let term = id.toLowerCase().replace(/ /g, '').trim()
    if (term.includes('bianca')) term = 'bianca'
    list = gifData && gifData.filter(i => {
        let val = null
        if (
            i.includes('_' + term + '_') ||
            i.includes('_' + term + '.') ||
            i.includes(term + '_')
        ) {
            val = i
        }
        return val
    })

    let video, link2, link3, link4 = null

    if (product.type === 'clip') {
        video = `https://data.powershotz.com/previews/prev_${product.id}.mp4`
    } else {
        const mData = modelData ?
            modelData.find(i => i.model_name === product.title)
            : null

        const aeClipName = mData && mData.ae_clip
            ? mData.ae_clip
            : null

        video = aeClipName
            ? `https://data.powershotz.com/aeVideos/${aeClipName}`
            : null

        const name = product.title.toLowerCase().replace(/ /g, '')
        link2 = `https://data.powershotz.com/models2/${name}-2.jpg`
        link3 = `https://data.powershotz.com/models2/${name}-3.jpg`
        link4 = `https://data.powershotz.com/models2/${name}-4.jpg`
    }

    array.push(link)
    link2 && array.push(link2)
    link3 && array.push(link3)
    link4 && array.push(link4)
    video && array.push(video)

    list.map(i => array.push(`https://data.powershotz.com/gif/${i}`))
    return array
}

export default getMedia